import Component from '../lib/component.js';
import store from '../store/index.js';

import {cleanPrice, numberThousandSeparator} from '../../../utils';
import PDPVirtualCart from "../../VirtualCart/PDPVirtualCart";

export default class ParticipationRangeInput extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.ucesce-range-desktop')
        });
    }

    getMinMaxAndValueForRangeInput = () => {
        const { tariff, participation } = store.state;
        const { participation: tariffParticipation, price, maxParticipationForActivation, minParticipationForActivation} = tariff;

        // let totalParticipation = cleanPrice(tariffParticipation);
        let intPrice = cleanPrice(price);
        const pdpVc = new PDPVirtualCart();
        let pdpVcData = pdpVc.getAll();

        const maxValueForRange = intPrice - minParticipationForActivation;
        let minValueForRange = intPrice - maxParticipationForActivation;
        if (maxParticipationForActivation > intPrice) {
            minValueForRange = 0;
        }

        if(pdpVcData.tariff.participation) {
            minValueForRange = pdpVcData.tariff.participation;
        }

        // if (participation > cleanPrice(maxParticipationForActivation)) {
        //     store.dispatch('setParticipation', cleanPrice(maxParticipationForActivation));
        // }
        //
        // if (participation < cleanPrice(minParticipationForActivation)) {
        //     store.dispatch('setParticipation', cleanPrice(minParticipationForActivation));
        // }
        return {
            minValueForRange,
            maxValueForRange,
            value: participation,
        }
    }

    setMinMaxAndValuetoRangeInput = () =>{
        /*const { maxValueForRange, minValueForRange } = this.getMinMaxAndValueForRangeInput();
        const participationInputElement = this.element.querySelector('#ucesce');
        if (participationInputElement) {
            participationInputElement.min = `${minValueForRange}`;
            participationInputElement.max = `${maxValueForRange}`;
            participationInputElement.value = `${minValueForRange}`;
        }*/
    }


    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const { tariff, participation } = store.state;
        const { price } = tariff;
        const { minValueForRange, maxValueForRange, value} = this.getMinMaxAndValueForRangeInput();


        // this.element.innerHTML = `
        //      <input type="range" id="ucesce" name="ucesce" value="${value}" min="${minValueForRange}" max="${maxValueForRange}" step="100">
        // `;

        this.setMinMaxAndValuetoRangeInput();
        // this.attachEventToInput();

    }
}
