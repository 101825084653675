// import Tariff from "./modules/VirtualCart/Tariff";
// import store from './modules/Sticky/store/index';

import {initBoosters, initBoostersCart} from "./booster"
import LoaderController from './loader'
const bpo_debug = true

/* Includes polyfill */
import {cleanPrice} from "./utils";
import DebugToolbar from './debugToolbar';


export const processNameToSend = {
    postpaid: 'Novi broj',
    mnp: 'Prenos broja u A1 mrežu',
    'renewal;cc2pop': 'Obnova ugovora',
    'pre2pop': 'Prelaz na postpaid',
    'add_hw;add_3rd_party_hw': 'Dodavanje uređaja',
    'add_accessory;accessories': 'Dodatna oprema na rate',
    'add_vip_sim': 'Dodavanje popusta',
}

if(!Array.prototype.includes){
    //or use Object.defineProperty
    Array.prototype.includes = function(search){
        return !!~this.indexOf(search);
    }
}
if(!Array.prototype.indexOf){
    Array.prototype.indexOf = (function(Object, max, min){
        "use strict";
        return function indexOf(member, fromIndex) {
            if(this===null||this===undefined)throw TypeError("Array.prototype.indexOf called on null or undefined");

            var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
            if (i < 0) i = max(0, Len+i); else if (i >= Len) return -1;

            if(member===void 0){ for(; i !== Len; ++i) if(that[i]===void 0 && i in that) return i; // undefined
            }else if(member !== member){   for(; i !== Len; ++i) if(that[i] !== that[i]) return i; // NaN
            }else                           for(; i !== Len; ++i) if(that[i] === member) return i; // all else

            return -1; // if the value was not found, then return -1
        };
    })(Object, Math.max, Math.min);
}
/* forEach polyfill */
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/* MouseEvent polyfill */
if (typeof MouseEvent !== 'function') {
    (function (){
        var _MouseEvent = window.MouseEvent;
        window.MouseEvent = function (type, dict){
            dict = dict || {};
            var event = document.createEvent('MouseEvents');
            event.initMouseEvent(
                type,
                (typeof dict.bubbles == 'undefined') ? true : !!dict.bubbles,
                (typeof dict.cancelable == 'undefined') ? false : !!dict.cancelable,
                dict.view || window,
                dict.detail | 0,
                dict.screenX | 0,
                dict.screenY | 0,
                dict.clientX | 0,
                dict.clientY | 0,
                !!dict.ctrlKey,
                !!dict.altKey,
                !!dict.shiftKey,
                !!dict.metaKey,
                dict.button | 0,
                dict.relatedTarget || null
            );
            return event;
        }
    })();
}
function removeCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
function removeCartDataIfPurchaseIsSuccess() {
    const purchaseValue = anvilGetCookie('purchase');
    if (purchaseValue === 'success') {
        localStorage.removeItem('virtualCart')
        removeCookie('purchase');
    }
}

/* Object.values() polyfill */
if (!Object.values) Object.values = o=>Object.keys(o).map(k=>o[k]);

window.virtualCart = null;
if (window.hasOwnProperty('VirtualCart')) {
    removeCartDataIfPurchaseIsSuccess();
    window.virtualCart = new VirtualCart();  // eslint-disable-line no-undef
    window.virtualCart.setAllFromStorage();

}


/**
 * @param {array} items
 * @param {string} key
 * @param {string} val
 */
function findInArray(items, key, val) {
    let found = null
    items.forEach((item) => {
        if (item[key] === val) {
            found = item
        }
    })
    return found
}

window.findInArray = findInArray;

function initStartPageCarousel() {
    const utils = window.fizzyUIUtils;

    let flkty = [];

    let rezCheck = document.body.classList.contains('pthclass_privatni');
    let bizCheck = document.body.classList.contains('pthclass_poslovni');

    let prevNextButtonsOption = true;
    if(rezCheck === true || bizCheck === true) {
        prevNextButtonsOption = false;
    }

    let carousels = document.getElementsByClassName("js-spc-carousel");
    for (let i = 0; i < carousels.length; i++) {
        let obj = new Flickity(carousels[i], {
            cellAlign: "left",
            contain: true,
            prevNextButtons: prevNextButtonsOption,
            autoPlay: 6000,
        })

        flkty.push(obj);
    }


    // elements
    let carouselNavigation = document.querySelector('.Spc-navigation')
    if (!carouselNavigation) {
        return;
    }
    let navigationCells = utils.makeArray(carouselNavigation.children);

    // update on select
    flkty[0].on('select', () => {
        let previousSelectedSlide = carouselNavigation.querySelector('.is-selected');
        let selectedSlide = carouselNavigation.children[flkty[0].selectedIndex];
        if (previousSelectedSlide && selectedSlide) {
            previousSelectedSlide?.classList.remove('is-selected');
            selectedSlide?.classList.add('is-selected');
        }

    });

    if (carouselNavigation) {
        carouselNavigation.addEventListener('click', (event) => {
            if (!matchesSelector(event.target, '.Spc-navigation-cell')) {
                return
            }

            let index = navigationCells.indexOf(event.target);
            flkty[0].select(index)
        });
    }

}

window.initStartPageCarousel = initStartPageCarousel;

//main_menu.html JS STARTS HERE

var delayTimer = null
function searchFromModal() {
    let lookup = $('#fullModalInternal')[0].value
    if (lookup) {
        window.location = `${window.location.origin}/{{$searchLink}}?lookup=${lookup}`
    }
}
function searchSuggestions() {
    let noResElem = $('#noResults')
    let loadingElem = $('#loading')
    let suggestElem = $('#suggestTitle')

    noResElem.hide()
    suggestElem.hide()
    loadingElem.show()

    let lookup = $('#fullModalInternal')[0].value
    if (lookup) {
        fetch(`/api/search?lookup=${lookup}`).then(
            (resp) => {
                resp.json().then((data) => {
                    loadingElem.hide()
                    if (data.length > 0) {
                        suggestElem.show()
                        let container = $('.p-search-modal__suggestions-items')[0]
                        while (container.children.length > 0) {
                            container.children[0].remove()
                        }
                        data.forEach((result) => {
                            let element = document.createElement('a')
                            element.setAttribute('href', result.url)
                            element.className = 'a-01-text__secondary-bold--xl'
                            element.innerText = result.title
                            container.append(element)
                        })
                    } else {
                        noResElem.show()
                    }
                })
            }
        )
    }
}

const createDrawer = (id, content) => {
    const prefix = 'prop-drawer';
    const idFull = `${prefix}-${id}`;
    const drawerHTML = `<div class="o-drawer js-drawer generated-drawer special-id-${idFull}" id="${idFull}">
        <div class="o-drawer__top-bar">
            <div class="close-icon">
                <img src="https://cdn.a1.rs/a1website/img/a1/icons/icon-x-close.svg" alt="Close" width="20px" height="18px">
            </div>
        </div>
        <div class="p-drawer-content">
            ${content}
        </div>
    
    </div>
    <div class="overlay  generated-drawer-overlay" data-toggle-drawer="${idFull}"></div>`;

    $(document.body).append(drawerHTML);

    $(`[data-toggle-drawer="${idFull}"]`).on('click', function() {
        $(`#${idFull}`).addClass('is-on');
    })
    $(`#${idFull} .close-icon`).on('click', function() {
        let toHide = $(`.special-id-${idFull}`)
        for(let i = 0; i < toHide.length; i++) {
            let item = toHide[i]
            $(item).removeClass("is-on")
            if($('body').css('overflow') === "hidden") {
                $('body').css('overflow', 'unset')
            }
        }
        this.parentElement.parentElement.classList.remove('is-on');
    })

    //close-icon
};

window.createDrawer = createDrawer;

function delayRefresh() {
    if (delayTimer) {
        clearTimeout(delayTimer)
    }
    delayTimer = setTimeout(() => {
        searchSuggestions()
    }, 1000)
}
$(document).ready(() => {
    $(".js-menu-item").mouseenter(function() {
        $(this).addClass('active');
        $(this).find('.submenu-wrapper a').delay(80).each(function(i) {
            $(this).delay(30 * i).queue(function() {
                $(this).addClass("show").dequeue();
            })
        })
    });
    $(".js-menu-item").mouseleave(function() {
        $('.submenu-wrapper a').removeClass('show');
        $(this).removeClass('active');
    }).mouseleave();
    $('#fullModalInternal').on('input', () => {
        delayRefresh()
    })

    // const debugToolbar = new DebugToolbar();
    const debugToolbar = DebugToolbar.getInstance();
    debugToolbar.init();

})

window.searchFromModal = searchFromModal;

//main.menu.html JS ENDS HERE

//mobile_menu.html JS STARTS HERE

function startMobileMenu(i1, i2, i3){
    var numberOfLevels = parseInt(i1);
    var mobileMenuItemLinks = document.querySelectorAll('div.hasSubmenu > a');
    if (mobileMenuItemLinks && mobileMenuItemLinks.length) {
        mobileMenuItemLinks.forEach(function(link) {
            link.onclick = function (e) {
                e.preventDefault();
            }
        });
    }

    var currentPathClass = i2;
    var mobileMenuTopLevelItem = document.querySelector('[data-path="' + currentPathClass + '"]');

    if (mobileMenuTopLevelItem) {
        $(mobileMenuTopLevelItem).find('.item__content').addClass('opened');
        $(mobileMenuTopLevelItem).find('.item__title').removeClass('text-grayscale-regular');
        $(mobileMenuTopLevelItem).find('.item__title').addClass('text-primary');
    }

    var currentPathSecondLevelClass = i3; //js-mobile-navbar-item
    var mobileMenuSecondLevelItem = document.querySelector('[data-path="' + currentPathSecondLevelClass + '"]');

    if (mobileMenuSecondLevelItem && mobileMenuSecondLevelItem.getAttribute('data-path') === currentPathSecondLevelClass && numberOfLevels > 1) {

        $(".js-mobile-navbar-item").each(function() {
            $(mobileMenuSecondLevelItem).find(".o-mobile-menu-navbar__subitems").removeClass("opened");
        });
        $(mobileMenuSecondLevelItem).find(".o-mobile-menu-navbar__subitems").addClass("opened");
        $(mobileMenuSecondLevelItem).find(".o-mobile-menu-navbar__subitems a").each(function() {
            $(this).addClass('show');
            if (this.href === window.location.href) {
                $(this).addClass('active-submenu-item');
            }
        });

        $(mobileMenuSecondLevelItem).find(".circle-plus").addClass('opened-plus');
    }
    $(document).ready(function() {

       initSwiperSlider();

        $('a[data-toggle-drawer]').on('click', function(ev) {
            let drawerId =  ev.currentTarget.getAttribute('data-toggle-drawer')
            if(drawerId) {
                let mainDrawerNav = $('#'+drawerId).find('.o-tabview__navigation');
                let secondDrawerNav = $(mainDrawerNav).find('.o-tabview');
                let desiredNavElement = $(secondDrawerNav).children()[0];
                $(desiredNavElement).trigger('click');
            }
        })

        // all tariffs platinum fix
        $('.all-tariffs-wrapper').on('click', "span[data-toggle-drawer]", function(e) {
            let currentTarget = $(e.currentTarget);
            let attr = $(currentTarget).attr('data-toggle-drawer');
            $('#'+attr).addClass('is-on');
        })

        $('.submenu-mobile-item').on('click', function(ev) {
            $('.active-submenu-item').removeClass('active-submenu-item');
            ev.stopPropagation();
        })
        $(".js-mobile-navbar-item").on('click', function() {
            $('.submenu-mobile-item.show').removeClass('show');
            $(this).find('.submenu-wrapper-mobile a').delay(80).each(function(i) {
                $(this).delay(30 * i).queue(function() {
                    $(this).addClass("show").dequeue();
                })
            })
        });

        $('.mobile-submenu-item-link').on('click', function(){
            var circlesWithPlus = document.querySelectorAll('.circle-plus');
            if (circlesWithPlus.length > 0) {
                for(var i = 0; i < circlesWithPlus.length; i++) {
                    if ($(this).find('.circle-plus').length > 0) {
                        if (circlesWithPlus[i].parentNode.parentNode.href !== this.href) {
                            $(circlesWithPlus[i]).removeClass('opened-plus');
                        }

                    }
                }
                for(var i = 0; i < circlesWithPlus.length; i++) {
                    if ($(this).find('.circle-plus').length > 0) {
                        if (circlesWithPlus[i].parentNode.parentNode.href === this.href) {
                            $(this).find('.circle-plus').toggleClass('opened-plus');
                            break;
                        }
                    }
                }
            }
            // $('.circle-plus').each((index, val) => {
            //     if ($(this).find('.circle-plus').length > 0) {
            //
            //         if (val.parentNode.parentNode.href === this.href) {
            //             cnt++;
            //             $(this).find('.circle-plus').toggleClass('opened-plus');
            //         } else {
            //             $(val).removeClass('opened-plus');
            //         }
            //
            //     }
            //
            // });
        })
    })
}
window.startMobileMenu = startMobileMenu;

//mobile_menu.html JS ENDS HERE

function serializeFormDataDeviceListFilter(form) {
    return form
        .serializeArray()
        .filter(dataItem => dataItem.name !== 'select-model')
        .reduce((accumulator, currentValue) =>
            accumulator?.name
                ? `${accumulator.name}=${accumulator.value}&${currentValue.name}=${currentValue.value}`
                : `${accumulator}&${currentValue.name}=${currentValue.value}`
        );
}
window.serializeFormDataDeviceListFilter = serializeFormDataDeviceListFilter;



const ctaIgnore = ['js-slider-button', 'back-to-top', 'js-scroll-to-top', 'app-store-icon', 'filters-reset-link'];

$(document).ready(function() {
    let spcCarousel = document.querySelector('.js-spc-carousel');
    initStartPageCarousel()
    if (typeof(spcCarousel) !== 'undefined' && spcCarousel !== null){
        initStartPageCarousel()
        setTimeout(function() {
            window.dispatchEvent(new Event('resize'));
        }, 1000);
        setTimeout(function() {
            window.dispatchEvent(new Event('resize'));
        }, 2000);
        setTimeout(function() {
            window.dispatchEvent(new Event('resize'));
        }, 3000);
    }

    // CTA DATA LAYER START
    $('img').on('click', function(e) {
        let shouldIgnore = false;
        ctaIgnore.forEach(item => {
            if ([...e.currentTarget.classList].includes(item)) {
                shouldIgnore = true;
            }
        });
        if ($(e.currentTarget).closest('.js-slider-button').length) { // if is child of button
            shouldIgnore = true;
        }
        if ($(e.currentTarget).closest("[type^='button']").length) {
            shouldIgnore = true;
        }
        if ($(e.currentTarget).closest('.close-icon').length) {
            shouldIgnore = true;
        }
        if (!$(e.target).attr('data-dl-event') && !shouldIgnore) {
            // dataLayer.push({
            //     'event': 'ctaClick',
            //     ctaText: e.currentTarget.src,
            //     ctaPosition: '',
            //     ctaType: 'image'
            // });
        }
    });

    $('a').on('click', function(e) {
        let shouldIgnore = false;
        let ctaType = 'link';
        if ($(e.currentTarget).attr('type') === 'button') {
            ctaType = 'button';
        }
        if ($(e.currentTarget).attr('data-modal-id')) {
            shouldIgnore = true;
        }
        if ($(e.currentTarget).attr('data-toggle-drawer')) {
            shouldIgnore = true;
        }
        ctaIgnore.forEach(item => {
            if ([...e.currentTarget.classList].includes(item)) {
                shouldIgnore = true;
            }
        });
        if (e.currentTarget?.href === '#' || !e.currentTarget?.href) {
            shouldIgnore = true;
        }
        if (!$(e.currentTarget).attr('data-dl-event') && !shouldIgnore) {
            dataLayer.push({
                'event': 'ctaClick',
                ctaText: e.currentTarget?.innerText,
                ctaPosition: '',
                ctaType: ctaType
            });
        }

    });

    $('button').on('click', function(e) {
        let shouldIgnore = false;
        ctaIgnore.forEach(item => {
            if ([...e.currentTarget.classList].includes(item)) {
                shouldIgnore = true;
            }
        });
        if (!$(e.currentTarget).attr('data-dl-event') && !shouldIgnore) {
            dataLayer.push({
                'event': 'ctaClick',
                ctaText: e.currentTarget?.innerText,
                ctaPosition: '',
                ctaType: 'button'
            });
        }
    })

    // CTA DATA LAYER END

    // DATA LAYER PLP FILTER START
    function getSelectedFilterOptions() {
        let selectedCategoriesString = '';
        $('[name="kategorija"]:checked').each((key, el) => {
            selectedCategoriesString = `${selectedCategoriesString}${selectedCategoriesString && ','}${el.id}`
        })
        return {
            filterTariff: $('#active_tariff_name').text().split(/\s/).join(''),
            filterDeviceBrand: $('#select-brand option:selected').text().split(/\s/).join(''),
            filterDeviceModel: $('#select-model option:selected').text().split(/\s/).join(''),
            filterPaymentMethod: $('[name="placanje"]:checked').val(),
            filterCategory: selectedCategoriesString,
        }
    }

    $('#drawer-plp-filters .o-slide-down__title').on('click', function(e) {
        let filterAction = 'closed';
        if (e.currentTarget.classList.contains('on')) {
            filterAction = 'opened'
        }
        dataLayer.push({
            'event': 'filterInteraction',
            filterAction:  filterAction, //'{{opened / closed / applied / canceled}}',
            ...getSelectedFilterOptions(),
            // filterTariff: '{{selected tariff}}',
            // filterDevice: '{{selected device}}',
            // filterPaymentMethod: '{{slected payment method}}',
            // filterCategory: '{{selected category}}'
        });
    });

    $('#select-brand').on('change', function(e) {
        dataLayer.push({
            'event': 'filterInteraction',
            filterAction:  'applied',
            ...getSelectedFilterOptions(),
        });
    });
    $('#select-model').on('change', function(e) {
        dataLayer.push({
            'event': 'filterInteraction',
            filterAction:  'applied',
            ...getSelectedFilterOptions(),
        });
    });

    $('[name="placanje"]').on('change', function() {
        dataLayer.push({
            'event': 'filterInteraction',
            filterAction:  'applied',
            ...getSelectedFilterOptions(),
        });
    });

    $('[name="kategorija"]').on('change', function() {
        dataLayer.push({
            'event': 'filterInteraction',
            filterAction:  'applied',
            ...getSelectedFilterOptions(),
        });
    });

    $('.filters-reset-link').on('click', function() {
        setTimeout(() => {
            dataLayer.push({
                'event': 'filterInteraction',
                filterAction:  'canceled',
                ...getSelectedFilterOptions(),
            });
        }, 500);
    });

    $('.t-plp__wrapper .m-tariff-card__cta-button a').on("click",function (e) {
        setTimeout(() => {
            dataLayer.push({
                'event': 'filterInteraction',
                filterAction:  'applied',
                ...getSelectedFilterOptions(),
            });
        }, 500);
    });
    // DATA LAYER PLP FILTER END
});
const deviceTypeNames = {
    '1': 'Telefon',
    '2': 'Dodatna oprema',
    '7': 'TV/Laptop',
    '8': 'Gedžeti',
    '6': 'Tableti/Konzole',
    '3': 'Modemi i ruteri',
}
function clearPrice(stringPrice) {
    if (stringPrice && typeof stringPrice === "string") {
        return parseInt(stringPrice.replace('.', ''));
    }
    if (typeof stringPrice === 'number') {
        return stringPrice;
    }
    return 0;
}

function formatPriceForDL(price) {
    return +parseFloat(clearPrice(price)).toFixed(2);
}

function setInsuranceAddToCartDataLayerPDP(tmpVc) {
    const { insuranceName, insuranceType, insurancePrice, isSelected } = tmpVc?.insurance?.getAll();
    const { deviceName } = tmpVc?._device?.getAll();
    const { name: tariffName} = tmpVc._tariff.getAll();
    setInsuranceAddToCartDataLayer({insuranceName, insuranceType, insurancePrice, deviceName, tariffName, isSelected})
}
function setInsuranceAddToCartDataLayer({insuranceName, insuranceType, insurancePrice, deviceName, tariffName, isSelected}) {
    const productItem = {
        name: `${deviceName} - ${insuranceName}`,
        id: insuranceType,
        price: +parseFloat(clearPrice(insurancePrice)).toFixed(2),
        brand: 'a1',
        category: 'Osiguranje uredjaja',
        quantity: 1,
        // dimension20: `${deviceName} ${tariffName}`,
    };
    const dataToSend = {
        'event': 'eec.addToCart',       // name of an event. In this case, always stays as eec.addToCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor RSD
            'add': {                        // name of an action. In this case, always stays as add
                // 'actionField': {
                //     'list': `${deviceType}`         // optional. name of a list from which the product was added to a cart
                // },
                'products': [                   // list of products a user has added to cart
                    productItem,
                ]
            }
        }
    };

    if (isSelected) {
        window.dataLayer.push(dataToSend);

        // SALES FORCE
        const { insurance } = virtualCart.getAll();

        const dlsfObject = {
            "event": 'dlSf',
            "description": 'insuranceAdd',
            "insurance": {
                insuranceName: insurance?.insuranceName,
                isSelected: insurance?.isSelected,
            }
        };

        window.dataLayer.push(dlsfObject);
    }

}
function setAdditionAddToCartDataLayer({id, bpo, price, title, tariffName}) {
    const productItem = {
        name: `${tariffName} - ${title}`,
        id: bpo,
        price: +parseFloat(clearPrice(price)).toFixed(2),
        brand: 'a1',
        category: 'Tarifni dodaci',
        quantity: 1,
        // dimension20: `${deviceName} ${tariffName}`,
    };
    const dataToSend = {
        'event': 'eec.addToCart',       // name of an event. In this case, always stays as eec.addToCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor RSD
            'add': {                        // name of an action. In this case, always stays as add
                // 'actionField': {
                //     'list': `${deviceType}`         // optional. name of a list from which the product was added to a cart
                // },
                'products': [                   // list of products a user has added to cart
                    productItem,
                ]
            }
        }
    };

    window.dataLayer.push(dataToSend);


    // SALES FORCE

    // SALES FORCE
    const dlsfObject = {
        "event": 'dlSf',
        "description": 'additionAdd',
        "tariffAddon": {
            'id': bpo,
            'price': formatPriceForDL(price),
            'title': title,
        },
    };
    //
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);
}
function setAdditionRemoveFromCartDataLayer({id, bpo, price, title}) {
    const productItem = {
        name: title,
        id: bpo,
        price: +parseFloat(clearPrice(price)).toFixed(2),
        brand: 'a1',
        category: 'Tarifni dodaci',
        quantity: 1,
        // dimension20: `${deviceName} ${tariffName}`,
    };
    const dataToSend = {
        'event': 'eec.removeFromCart',          // name of an event. In this case, always stays as eec.removeFromCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor  RSD
            'remove': {                     // name of an action. In this case, always stays as remove
                // 'actionField': {
                //     'list': `${deviceCategoryNames[device?.deviceType]}`         // optional. name of a list from which the product was added to a cart
                // },
                'products': [                   // list of products a user has added to cart
                    productItem
                ]
            }
        }
    };

    window.dataLayer.push(dataToSend);

    const ga4Product = {
        item_name: productItem.name,
        item_id: productItem.id,
        price: productItem.price,
        currency: 'RSD',
        discount: 0.00,
        item_brand: productItem.brand,
        item_category: productItem.category,
        item_variant: productItem.variant,
        index: 1,
        quantity: 1,
    }
    window.dataLayer.push(dataToSend);

    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            items: [ga4Product]
        }
    });
    // SALES FORCE

    const dlsfObject = {
        "event": 'dlSf',
        "description": 'additionRemove',
        "tariffAddon": {
            'id': bpo,
            'price': formatPriceForDL(price),
            'title': title,
        },
    };
    //
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);
}
function removeInsuranceFromCartDataLayerPDP(tmpVc) {
    const { insuranceName, insuranceType, insurancePrice } = tmpVc?.insurance?.getAll();
    const { deviceName } = tmpVc?._device?.getAll();
    const { name: tariffName} = tmpVc._tariff.getAll();
    setInsuranceAddToCartDataLayer({insuranceName, insuranceType, insurancePrice, deviceName, tariffName})
}
function removeInsuranceFromCartDataLayer({insuranceName, insuranceType, insurancePrice, deviceName, tariffName}) {
    const productItem = {
        name: `${deviceName} - ${insuranceName}`,
        id: insuranceType,
        price: +parseFloat(clearPrice(insurancePrice)).toFixed(2),
        brand: 'a1',
        category: 'Osiguranje uredjaja',
        quantity: 1,
        // dimension20: `${deviceName} ${tariffName}`,
    };

    const dataToSend = {
        'event': 'eec.removeFromCart',          // name of an event. In this case, always stays as eec.removeFromCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor  RSD
            'remove': {                     // name of an action. In this case, always stays as remove
                // 'actionField': {
                //     'list': `${deviceCategoryNames[device?.deviceType]}`         // optional. name of a list from which the product was added to a cart
                // },
                'products': [                   // list of products a user has added to cart
                    productItem
                ]
            }
        }
    };

    window.dataLayer.push(dataToSend);
    const ga4Product = {
        item_name: productItem.name,
        item_id: productItem.id,
        price: productItem.price,
        currency: 'RSD',
        discount: 0.00,
        item_brand: productItem.brand,
        item_category: productItem.category,
        item_variant: productItem.variant,
        index: 1,
        quantity: 1,
    }

    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            items: [ga4Product]
        }
    });


    // SALES FORCE
    const dlsfObject = {
        "event": 'dlSf',
        "description": 'insuranceRemove',
        "insurance": {
            insuranceName: insuranceName,
            isSelected: false,
        }
    };

    window.dataLayer.push(dlsfObject);
}

function setDataLayerAddToCartSingle(tmpVc, deviceType, itemName, itemCode, itemModel, isCash = false) {
    const { device, tariff, insurance } = tmpVc;
    // const insuranceProductItem = {
    //     name: insurance?.insuranceName,
    //     id: insurance?.insuranceType,
    //     price: +parseFloat(clearPrice(insurance?.insurancePrice)).toFixed(2),
    //     brand: 'a1',
    //     category: 'Osiguranje uredjaja',
    //     quantity: 1,
    //     dimension20: `${itemName} ${tariff?.name}`,
    // };

    window.dataLayer  = window.dataLayer || [];
    let productItem = {
        'name': `${itemName}`,      // name of a product that is currently viewed
        'id': itemCode,            // unique id (SKU) of a product
        'price': +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
        'brand': itemModel,           // brand/vendor of a product
        'category': `${deviceTypeNames[deviceType]}`,      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
        //
        //
        //
        // 'variant': device?.deviceMmid,       // product variant. If there are no variants, exclude this key from the dataLayer.push
        'quantity' : 1,              // product quantity
        // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
        // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
    };
    // if (device?.deviceMmid) {
    //     productItem['variant'] = device?.deviceMmid;
    // }
    const dataToSend = {
        'event': 'eec.addToCart',       // name of an event. In this case, always stays as eec.addToCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor RSD
            'add': {                        // name of an action. In this case, always stays as add
                // 'actionField': {
                //     'list': `${deviceType}`         // optional. name of a list from which the product was added to a cart
                // },
                'products': [                   // list of products a user has added to cart
                    productItem,
                    // insuranceProductItem,
                    // ...accessoryDLData,
                ]
            }
        }
    };

    window.dataLayer.push(dataToSend);

    // GA4
    if (productItem) {

        let variant = productItem.variant;

        if (productItem.id.startsWith('AD_')) {
            variant = productItem.id.replace('AD_', '');
        }
        // tmp logic (hardcode need to be fixed)
        let brand = productItem.brand;
        if (!brand && productItem.name.includes('iPhone')) {
            brand = 'Apple';
        }
        const ga4ToSend = [{
            item_name: productItem.name,
            item_id: productItem.id,
            price: productItem.price,
            currency: 'RSD',
            discount: 0.00,
            item_brand: brand,
            item_category: productItem.category,
            item_variant: variant,
            item_list_name: 'Cart',
            item_list_id: 'Cart',
            index: 1,
            quantity: 1,
        }];

        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                items: ga4ToSend,
            }
        });
    }

    // SALES FORCE

    const { gadgets, accessories } = virtualCart.getAll();

    let selectedAccOrGadget = null;

    if (gadgets && gadgets?.length) {
        const filtered = gadgets.filter(gadget => gadget?.accessoryCode === itemCode);
        if (filtered && filtered?.length) {
            selectedAccOrGadget = filtered[0];
        }
    }

    if (!selectedAccOrGadget && accessories && accessories?.length) {
        const filtered = accessories.filter(accessory => accessory?.accessoryCode === itemCode);
        if (filtered && filtered?.length) {
            selectedAccOrGadget = filtered[0];
        }
    }

    const dlsfObject = {
        "event": 'dlSf',
        "description": selectedAccOrGadget?.isGadget ? 'gadgetAdd' : 'accessoryAdd',
        "accessory": {
            'accessoryComercialName': selectedAccOrGadget?.accessoryComercialName,
            'accessoryId': selectedAccOrGadget?.accessoryId,
            'accessoryManufacturerName': selectedAccOrGadget?.accessoryManufacturerName,
            'accessoryName': selectedAccOrGadget?.accessoryName,
            'accessoryPrice': selectedAccOrGadget?.accessoryPrice,
            'accessoryType': selectedAccOrGadget?.isGadget ? '8' : '2',
            'isGadget': !!selectedAccOrGadget?.isGadget,
        },
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);
}
function setDataLayerAddToCartAccGadzet({}) {

}

function setDataLayerAddToCartSalesForce({ itemAccessories }) {

    const { device, insurance, tariff, accessories, paymentType, participation } = virtualCart.getAll();
    const isCash = paymentType === 'gotovina';
    let isDeviceNoTariff = false;
    //if (tariff?.tariffCode === 'SPO_PREPAIDR' || tariff?.tariffCode === 'STANDALONE') {
    if (tariff?.tariffCode === 'PREPAIDR' || tariff?.tariffCode === 'STANDALONE') {
        isDeviceNoTariff = true;
    }

    const dlsfDevice = {
        deviceCode: device?.deviceCode,
        deviceInstalments: isCash ? 0 : device?.deviceInstalments,
        deviceManufacturer: device?.deviceManufacturer,
        deviceName: device?.deviceName,
        deviceType: deviceTypeNames[device?.deviceType],
        deviceParticipation: participation,
        price: isCash ? formatPriceForDL(tariff?.priceCash) : formatPriceForDL(tariff?.price),
    }

    const dlsfObject = {
        "event": 'dlSf',
        "description": 'deviceAdd',
        "device": dlsfDevice,
    };

    let dlsfInsurance = null
    if (insurance?.isSelected) {
        dlsfInsurance = {
            insuranceName: insurance?.insuranceName,
            isSelected: insurance?.isSelected,
        }
        dlsfObject['insurance'] = dlsfInsurance;
        dlsfObject['description'] = 'bundleAdd';
    }
    let dlsfTariff = null;

    if (!isDeviceNoTariff) {
        dlsfTariff = {
            monthlyFee: tariff?.monthlyFee,
            name: tariff?.name,
            tariffCode: tariff?.tariffCode,
            tariffName: tariff?.tariffName,
        }
        dlsfObject['tariff'] = dlsfTariff;
        dlsfObject['description'] = 'bundleAdd';
    }

    if (itemAccessories) {
        let dlsfAccessories = accessories.map(acc => {
            return  {
                'accessoryComercialName': acc?.accessoryComercialName,
                'accessoryId': acc?.accessoryId,
                'accessoryManufacturerName': acc?.accessoryManufacturerName,
                'accessoryName': acc?.accessoryName,
                'accessoryPrice': acc?.accessoryPrice,
                'accessoryType': acc?.accessoryType,
                'isGadget': !!acc?.isGadget,
            }
        });

        dlsfAccessories = dlsfAccessories.filter(dlAccItem => {
            const doesExistsInItem = itemAccessories.filter(itemAcc => itemAcc?.accessorry_id === dlAccItem?.accessoryId)?.length > 0;
            if (doesExistsInItem) {
                const buttonForAddingAcc = document.querySelector(`a[data-acc-id="${dlAccItem?.accessoryId}"]`);
                if (buttonForAddingAcc) {
                    if (buttonForAddingAcc.className.indexOf('inverted') !== -1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }

            return false;
        });
        if (dlsfAccessories && dlsfAccessories?.length > 0) {
            dlsfObject['accessory'] = dlsfAccessories;
            dlsfObject['description'] = 'bundleAdd';
        }
    }



    // const dataToSend = {};

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);
}

function setDataLayerAddToCartAccGadzetSalesForce({ itemCode }) {
    const { accessories, paymentType, participation, gadgets } = virtualCart.getAll();

    let selectedItem = null;
    let currentlyAddedAccOrGadg = accessories.filter(acc => acc?.accessoryCode === itemCode);
    if (currentlyAddedAccOrGadg && currentlyAddedAccOrGadg?.length > 0) {
        selectedItem = currentlyAddedAccOrGadg[0];
    } else {
        currentlyAddedAccOrGadg = gadgets.filter(acc => acc?.accessoryCode === itemCode);
        if (currentlyAddedAccOrGadg && currentlyAddedAccOrGadg?.length > 0) {
            selectedItem = currentlyAddedAccOrGadg[0];
        }
    }

    const addedGadgedOrAcc = {
        'accessoryComercialName': selectedItem?.accessoryComercialName,
        'accessoryId': selectedItem?.accessoryId,
        'accessoryManufacturerName': selectedItem?.accessoryManufacturerName,
        'accessoryName': selectedItem?.accessoryName,
        'accessoryPrice': selectedItem?.accessoryPrice,
        'accessoryType': selectedItem?.isGadget ? '8' : '2',
        'isGadget': !!selectedItem?.isGadget,
    }

    const dlsfObject = {
        "event": 'dlSf',
        "description": selectedItem?.isGadget ? 'gadgetAdd' : 'accessoryAdd',
        "accessory": addedGadgedOrAcc,
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);

}
function setDataLayerAddToCart(tmpVc, deviceType, itemName, itemCode, itemModel, isCash = false, isAccOrGadz = false, itemAccessories = null) {
    const { device, tariff, insurance } = tmpVc;
    let isDeviceNoTariff = false;

    //if (tariff?.tariffCode === 'SPO_PREPAIDR' || tariff?.tariffCode === 'STANDALONE') {
    if (tariff?.tariffCode === 'PREPAIDR' || tariff?.tariffCode === 'STANDALONE') {
        isDeviceNoTariff = true;
    }
    let insuranceProductItem = null;


    if (insurance?.isSelected) {
        insuranceProductItem = {
            name: `${insurance?.insuranceName} - ${itemName}`,
            id: insurance?.insuranceType,
            price: +parseFloat(clearPrice(insurance?.insurancePrice)).toFixed(2),
            brand: 'a1',
            category: 'Osiguranje uredjaja',
            quantity: 1,
            // dimension20: `${itemName} ${tariff?.name}`,
        };
    }
    setTimeout(() => {
        const { accessories } = virtualCart.getAll();
        let accessoryDLData = accessories.map(acc => {
            return  {
                'name': acc?.accessoryName,      // name of a product that is currently viewed
                'id': acc?.accessoryCode,            // unique id (SKU) of a product
                'price': +parseFloat(clearPrice(isCash ? acc?.priceCash : acc?.accessoryPrice)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
                'brand': acc?.accessoryManufacturerName,           // brand/vendor of a product
                'category': deviceTypeNames['2'],      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
                // 'variant': device?.deviceMmid,       // product variant. If there are no variants, exclude this key from the dataLayer.push
                'quantity' : 1,              // product quantity
                // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
                // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
            }
        });

        if (itemAccessories) {
            accessoryDLData = accessoryDLData.filter(dlAccItem => {

                const doesExistsInItem = itemAccessories.filter(itemAcc => itemAcc?.code === dlAccItem?.id)?.length > 0;

                if (doesExistsInItem) {
                    const buttonForAddingAcc = document.querySelector(`a[data-acc-code="${dlAccItem?.id}"]`);
                    if (buttonForAddingAcc) {
                        if (buttonForAddingAcc.className.indexOf('inverted') !== -1) {
                            return true;
                        } else {
                            return false;
                        }

                    }
                }
            });
        }


        window.dataLayer  = window.dataLayer || [];
        let productItem = {
            'name': `${tariff?.name} - ${itemModel} ${itemName}`,      // name of a product that is currently viewed
            'id': `${tariff?.tariffCode}${itemCode ? ` - ${itemCode}` : ''}`,            // unique id (SKU) of a product
            'price': +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': `a1 - ${itemModel}`,           // brand/vendor of a product
            'category': `Tarifa - ${deviceType}`,      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            // 'variant': device?.deviceMmid,       // product variant. If there are no variants, exclude this key from the dataLayer.push
            'quantity' : 1,              // product quantity
            // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        };
        if (isDeviceNoTariff) {
            productItem = {
                'name': `${itemName}`,      // name of a product that is currently viewed
                'id': `${itemCode}`,            // unique id (SKU) of a product
                'price': +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
                'brand': itemModel,           // brand/vendor of a product
                'category': `${deviceType}`,      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
                // 'variant': device?.deviceMmid,       // product variant. If there are no variants, exclude this key from the dataLayer.push
                'quantity' : 1,              // product quantity
                // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
                // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
            };
        }
        if (device?.deviceMmid) {
            productItem['variant'] = device?.deviceMmid;
        }

        const products = [productItem, ...accessoryDLData];

        if (insuranceProductItem) {
            products.push(insuranceProductItem);
        }

        const uniqueArray = products.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === products.findIndex(obj => {
                return JSON.stringify(obj) === _thing;
            });
        });
        // productsFlat = products.

        let arrayToSend = uniqueArray;
        if (isAccOrGadz  && uniqueArray.length) {
            arrayToSend = [uniqueArray[0]];
        }

        const dataToSend = {
            'event': 'eec.addToCart',       // name of an event. In this case, always stays as eec.addToCart
            'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
                'currencyCode': 'RSD',         // the currency which is currently used by a visitor RSD
                'add': {                        // name of an action. In this case, always stays as add
                    // 'actionField': {
                    //     'list': `${deviceType}`         // optional. name of a list from which the product was added to a cart
                    // },
                    'products': arrayToSend,
                }
            }
        };
        window.dataLayer.push(dataToSend);



        const ga4ToSend = arrayToSend.map(arr => {
            let variant = arr.variant;

            if (arr.id.startsWith('AD_')) {
                variant = arr.id.replace('AD_', '');
            }
            const deviceBrand = arr.brand.replace('a1 - ', '');
            const itemName = arr.name.replace(`${deviceBrand} `, '');
            return {
                item_name: itemName,
                item_id: arr.id,
                price: arr.price,
                currency: 'RSD',
                discount: 0.00,
                item_brand: arr.brand,
                item_category: arr.category,
                item_variant: variant,
                item_list_name: 'PDP',
                item_list_id: 'PDP',
                quantity: 1,
            }
        })

        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                items: ga4ToSend,
            }
        });

        // SALES FORCE
        if (isAccOrGadz) {
            setDataLayerAddToCartAccGadzetSalesForce({ itemCode });
        } else {
            setDataLayerAddToCartSalesForce({
                itemAccessories
            });
        }


    });
}

function setDataLayerRemoveFromCart2(tmpVc, deviceType, itemName, itemCode, itemModel, isCash = false, acc) {
    const { device, tariff } = tmpVc;
    window.dataLayer  = window.dataLayer || [];
    let productItem = {
        'name': itemName,      // name of a product that is currently viewed
        'id': itemCode,            // unique id (SKU) of a product
        'price': +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
        'brand': itemModel,           // brand/vendor of a product
        'category': deviceTypeNames[deviceType],      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
        'variant':  itemCode ? itemCode.replace('AD_', '') : itemCode,       // product variant. If there are no variants, exclude this key from the dataLayer.push
        'quantity' : 1,              // product quantity
        // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
        // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
    };
    if (device?.deviceMmid) {
        productItem['variant'] = device?.deviceMmid;
    }
    const dataToSend = {
        'event': 'eec.removeFromCart',          // name of an event. In this case, always stays as eec.removeFromCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor  RSD
            'remove': {                     // name of an action. In this case, always stays as remove
                'products': [                   // list of products a user has added to cart
                    productItem,
                ]
            }
        }
    };
    window.dataLayer.push(dataToSend);

    let brand = productItem.brand;
    if (!brand && productItem.name.includes('iPhone')) {
        brand = 'Apple';
    }
    const ga4Product = {
        item_name: productItem.name,
        item_id: itemCode,
        price: productItem.price,
        currency: 'RSD',
        discount: 0.00,
        item_brand: brand,
        item_category: productItem.category,
        item_variant: productItem.variant,
        index: 1,
        quantity: 1,
    }
    window.dataLayer.push(dataToSend);

    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            items: [ga4Product]
        }
    });


    const comName = acc?.accessoryComercialName ? acc?.accessoryComercialName : `${acc?.accessoryManufacturerName} ${acc?.accessoryName}`
    // SALES FORCE
    const dlsfObject = {
        "event": 'dlSf',
        "description": acc?.isGadget ? 'gadgetRemove' : 'accessoryRemove',
        "accessory": {
            'accessoryComercialName': comName,
            'accessoryId': acc?.accessoryId,
            'accessoryManufacturerName': acc?.accessoryManufacturerName,
            'accessoryName': acc?.accessoryName,
            'accessoryPrice': acc?.accessoryPrice,
            'accessoryType': acc?.isGadget ? '8' : '2',
            'isGadget': !!acc?.isGadget,
        },
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);
}
function removeFirstWord(str) {
    if (!str) {
        return '';
    }
    const indexOfSpace = str.indexOf(' ');

    if (indexOfSpace === -1) {
        return '';
    }

    return str.substring(indexOfSpace + 1);
}
function setDataLayerViewCart(vc) {
    const { tariff, device, paymentType, insurance, accessories, gadgets, tariffAddons } = vc;
    const isCash = paymentType === 'gotovina';
    const isStandalone = tariff?.tariffCode === 'STANDALONE';
    //console.log(isStandalone, tariff, tariff?.tariffCode, 'isStandalone');
    window.dataLayer  = window.dataLayer || [];
    // tariffMontlyFeeWithoutDevice
    let price = +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2);

    if (!device?.deviceName && tariff?.tariffMontlyFeeWithoutDevice) {
        price = +parseFloat(clearPrice(tariff?.tariffMontlyFeeWithoutDevice)).toFixed(2);
    }
    let brand = device?.deviceName?.split(' ')[0];

    if (!brand) {
        brand = 'a1';
    }
    let deviceProduct = {
        'name': `${tariff?.name}${removeFirstWord(device?.deviceName) ? ` - ${removeFirstWord(device?.deviceName)}` : ''}`,      // name of a product that is currently viewed
        'id': `${tariff?.tariffCode}${device?.deviceCode ? ` - ${device?.deviceCode}` : ''}`,            // unique id (SKU) of a product
        'price': price,            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
        'brand': `${brand !== 'a1' && device?.deviceCode ? `a1 - ${brand}` : brand}`,           // brand/vendor of a product
        'category': `Tarifa${deviceTypeNames[device?.deviceType] ? ` - ${deviceTypeNames[device?.deviceType]}` : ''}`,      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
        'quantity' : 1,              // product quantity
        // 'dimension20': '{{tariff}}',//tmp removed //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
        // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
    };
    if (isStandalone) {
        deviceProduct = {
            'name': removeFirstWord(device?.deviceName),      // name of a product that is currently viewed
            'id': device?.deviceCode,            // unique id (SKU) of a product
            'price': price,            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': brand,           // brand/vendor of a product
            'category': deviceTypeNames[device?.deviceType],      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            'quantity' : 1,              // product quantity
            // 'dimension20': '{{tariff}}',//tmp removed //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        };
    }

    if (device?.deviceMmid) {
        deviceProduct['variant'] = device?.deviceMmid;
    }

    let insuranceProductItem = null;
    if (insurance?.isSelected) {
        insuranceProductItem = {
            name: `${insurance?.insuranceName} - ${device?.deviceName}`,
            id: insurance?.insuranceType,
            price: +parseFloat(clearPrice(insurance?.insurancePrice)).toFixed(2),
            brand: 'a1',
            category: 'Osiguranje uredjaja',
            quantity: 1,
            // dimension20: `${device?.deviceName} ${tariff?.name}`,
        };
    }

    let tariffAddonsProductItems = [];

    // if (tariffAddons?.length) {
    //     tariffAddonsProductItems = tariffAddons?.map(tariffAddonSingle => {
    //         return {
    //             name: tariffAddonSingle?.title,
    //             id: tariffAddonSingle?.bpo,
    //             price: +parseFloat(clearPrice(tariffAddonSingle?.price)).toFixed(2),
    //             brand: 'a1',
    //             category: 'Tarifni dodaci',
    //             quantity: 1,
    //             // dimension20: `${deviceName} ${tariffName}`,
    //         }
    //     })
    // }

    const accessoryDLData = accessories?.map(acc => {
        return  {
            'name': acc?.accessoryName,      // name of a product that is currently viewed
            'id': acc?.accessoryCode,            // unique id (SKU) of a product
            'price': +parseFloat(clearPrice(isCash ? acc?.priceCash : acc?.accessoryPrice)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': acc?.accessoryManufacturerName,           // brand/vendor of a product
            'category': deviceTypeNames['2'],      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            'variant': acc?.accessoryCode.replace('AD_', ''),       // product variant. If there are no variants, exclude this key from the dataLayer.push
            'quantity' : 1,              // product quantity
            // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        }
    });

    const gadgetsDLData = gadgets?.map(acc => {
        return  {
            'name': acc?.accessoryName,      // name of a product that is currently viewed
            'id': acc?.accessoryCode,            // unique id (SKU) of a product
            'price': +parseFloat(clearPrice(isCash ? acc?.priceCash : acc?.accessoryPrice)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': acc?.accessoryManufacturerName,           // brand/vendor of a product
            'category': deviceTypeNames['8'],      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            'variant': acc?.accessoryCode.replace('AD_', ''),       // product variant. If there are no variants, exclude this key from the dataLayer.push
            'quantity' : 1,              // product quantity
            // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        }
    });

    let products = [];
    if (tariff?.tariffCode || device?.deviceCode) {
        products.push(deviceProduct);
    }

    products = [
        ...products,
        ...accessoryDLData,
        ...gadgetsDLData,
        ...tariffAddonsProductItems,
    ];



    if (insuranceProductItem) {
        products.push(insuranceProductItem);
    }

    const ga4Products = products.map(p => {
        //console.log(p, "PPPP");
        return {
            item_name: p.name,
            item_id: p.id,
            price: p.price,
            currency: 'RSD',
            discount: 0.00,
            item_brand: p.brand,
            item_category: p.category,
            item_variant: p.variant,
            quantity: 1,
        }
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
            items: ga4Products,
        }
    });
}
function setDataLayerRemoveFromCart(vc) {
    const { tariff, device, paymentType, insurance, accessories, gadgets, tariffAddons } = vc;
    const isCash = paymentType === 'gotovina';
    window.dataLayer  = window.dataLayer || [];
    // tariffMontlyFeeWithoutDevice
    let price = +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2);

    if (!device?.deviceName && tariff?.tariffMontlyFeeWithoutDevice) {
        price = +parseFloat(clearPrice(tariff?.tariffMontlyFeeWithoutDevice)).toFixed(2);
    }
    let brand = device?.deviceName?.split(' ')[0];

    if (!brand) {
        brand = 'a1';
    }
    let deviceProduct = null;
    if (tariff?.tariffCode === 'STANDALONE') {
        deviceProduct = {
            'name': removeFirstWord(device?.deviceName),      // name of a product that is currently viewed
            'id': device?.deviceCode,            // unique id (SKU) of a product
            'price': price,            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': brand,           // brand/vendor of a product
            'category': deviceTypeNames[device?.deviceType],      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            'quantity' : 1,              // product quantity
            // 'dimension20': '{{tariff}}',//tmp removed //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        };
    } else {
        deviceProduct = {
            'name': `${tariff?.name}${removeFirstWord(device?.deviceName) ? ` - ${removeFirstWord(device?.deviceName)}` : ''}`,      // name of a product that is currently viewed
            'id': `${tariff?.tariffCode}${device?.deviceCode ? ` - ${device?.deviceCode}` : ''}`,            // unique id (SKU) of a product
            'price': price,            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': `${brand !== 'a1' && device?.deviceCode ? `a1 - ${brand}` : brand}`,           // brand/vendor of a product
            'category': `Tarifa${deviceTypeNames[device?.deviceType] ? ` - ${deviceTypeNames[device?.deviceType]}` : ''}`,      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            'quantity' : 1,              // product quantity
            // 'dimension20': '{{tariff}}',//tmp removed //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        };
    }


    if (device?.deviceMmid) {
        deviceProduct['variant'] = device?.deviceMmid;
    }

    let insuranceProductItem = null;
    if (insurance?.isSelected) {
        insuranceProductItem = {
            name: `${insurance?.insuranceName} - ${device?.deviceName}`,
            id: insurance?.insuranceType,
            price: +parseFloat(clearPrice(insurance?.insurancePrice)).toFixed(2),
            brand: 'a1',
            category: 'Osiguranje uredjaja',
            quantity: 1,
            // dimension20: `${device?.deviceName} ${tariff?.name}`,
        };
    }

    let tariffAddonsProductItems = [];

    if (tariffAddons?.length) {
        tariffAddonsProductItems = tariffAddons?.map(tariffAddonSingle => {
            return {
                name: tariffAddonSingle?.title,
                id: tariffAddonSingle?.bpo,
                price: +parseFloat(clearPrice(tariffAddonSingle?.price)).toFixed(2),
                brand: 'a1',
                category: 'Tarifni dodaci',
                quantity: 1,
                // dimension20: `${deviceName} ${tariffName}`,
            }
        })
    }

    const accessoryDLData = accessories?.map(acc => {
        return  {
            'name': acc?.accessoryName,      // name of a product that is currently viewed
            'id': acc?.accessoryCode,            // unique id (SKU) of a product
            'price': +parseFloat(clearPrice(isCash ? acc?.priceCash : acc?.accessoryPrice)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': acc?.accessoryManufacturerName,           // brand/vendor of a product
            'category': deviceTypeNames['2'],      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            'variant':  acc?.accessoryCode.replace('AD_', ''),       // product variant. If there are no variants, exclude this key from the dataLayer.push
            'quantity' : 1,              // product quantity
            // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        }
    });

    const gadgetsDLData = gadgets?.map(acc => {
        return  {
            'name': acc?.accessoryName,      // name of a product that is currently viewed
            'id': acc?.accessoryCode,            // unique id (SKU) of a product
            'price': +parseFloat(clearPrice(isCash ? acc?.priceCash : acc?.accessoryPrice)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': acc?.accessoryManufacturerName,           // brand/vendor of a product
            'category': deviceTypeNames['8'],      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            'variant': acc?.accessoryCode.replace('AD_', ''),       // product variant. If there are no variants, exclude this key from the dataLayer.push
            'quantity' : 1,              // product quantity
            // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        }
    });

    let products = [];
    if (tariff?.tariffCode || device?.deviceCode) {
        products.push(deviceProduct);
    }

    products = [
        ...products,
        ...accessoryDLData,
        ...gadgetsDLData,
        ...tariffAddonsProductItems,
    ];



    if (insuranceProductItem) {
        products.push(insuranceProductItem);
    }


    const dataToSend = {
        'event': 'eec.removeFromCart',          // name of an event. In this case, always stays as eec.removeFromCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor  RSD
            'remove': {                     // name of an action. In this case, always stays as remove
                // 'actionField': {
                //     'list': `${deviceCategoryNames[device?.deviceType]}`         // optional. name of a list from which the product was added to a cart
                // },
                'products': products,
            }
        }
    };

    const ga4Products = products.map(p => {
        return {
            item_name: p.name,
            item_id: p.id,
            price: p.price,
            currency: 'RSD',
            discount: 0.00,
            item_brand: p.brand,
            item_category: p.category,
            item_variant: p.variant,
            index: 1,
            quantity: 1,
        }
    });
    window.dataLayer.push(dataToSend);

    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            items: ga4Products
        }
    });

    // SALES FORCE
    removeAllFromCartDLSF(vc);
}

function removeAllFromCartDLSF(vc) {
    const { tariff, device, paymentType, insurance, accessories, gadgets, tariffAddons, participation } = virtualCart.getAll();
    const isCash = paymentType === 'gotovina';
    const isTariffNoDevice = !device?.deviceCode;
    let isDeviceNoTariff = false;

    //if (tariff?.tariffCode === 'SPO_PREPAIDR' || tariff?.tariffCode === 'STANDALONE') {
    if (tariff?.tariffCode === 'PREPAIDR' || tariff?.tariffCode === 'STANDALONE') {
        isDeviceNoTariff = true;
    }
    window.dataLayer  = window.dataLayer || [];
    // tariffMontlyFeeWithoutDevice
    // let price = +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2);
    //
    // if (!device?.deviceName && tariff?.tariffMontlyFeeWithoutDevice) {
    //     price = +parseFloat(clearPrice(tariff?.tariffMontlyFeeWithoutDevice)).toFixed(2);
    // }


    const dlsfObject = {
        "event": 'dlSf',
        "description": 'obriši korpu',
    }

    if (!isTariffNoDevice && device?.deviceCode) {
        dlsfObject['device'] = {
            deviceCode: device?.deviceCode,
            deviceInstalments: isCash ? 0 : device?.deviceInstalments,
            deviceManufacturer: device?.deviceManufacturer,
            deviceName: device?.deviceName,
            deviceType: deviceTypeNames[device?.deviceType],
            deviceParticipation: participation ? participation : 0,
            price: isCash ? formatPriceForDL(tariff?.priceCash) : formatPriceForDL(tariff?.price),
        }
    }

    if (insurance?.isSelected) {
        dlsfObject['insurance'] ={
            insuranceName: insurance?.insuranceName,
            isSelected: insurance?.isSelected,
        }
    }

    if (!isDeviceNoTariff && tariff?.tariffCode) {
        dlsfObject['tariff'] = {
            monthlyFee: isTariffNoDevice ? tariff?.tariffMontlyFeeWithoutDevice : tariff?.monthlyFee,
            name: tariff?.name,
            tariffCode: tariff?.tariffCode,
            tariffName: tariff?.tariffName,
        };
    }
    let accAndGadgetsArray = [];
    if (accessories && accessories.length > 0) {
        const dlsfAcc = accessories.map(acc => ({
            'accessoryComercialName': acc?.accessoryComercialName,
            'accessoryId': acc?.accessoryId,
            'accessoryManufacturerName': acc?.accessoryManufacturerName,
            'accessoryName': acc?.accessoryName,
            'accessoryPrice': acc?.accessoryPrice,
            'accessoryType': acc?.isGadget ? '8' : '2',
            'isGadget': false,
        }));
        accAndGadgetsArray = [...dlsfAcc];
    }

    if (gadgets && gadgets.length > 0) {
        const dlsfGadg = gadgets.map(acc => ({
            'accessoryComercialName': acc?.accessoryComercialName,
            'accessoryId': acc?.accessoryId,
            'accessoryManufacturerName': acc?.accessoryManufacturerName,
            'accessoryName': acc?.accessoryName,
            'accessoryPrice': acc?.accessoryPrice,
            'accessoryType': acc?.isGadget ? '8' : '2',
            'isGadget': true,
        }));
        accAndGadgetsArray = [...accAndGadgetsArray, ...dlsfGadg];
    }

    if (accAndGadgetsArray && accAndGadgetsArray?.length) {
        dlsfObject["accessory"] = accAndGadgetsArray;
    }

    if (tariffAddons && tariffAddons?.length > 0) {
        const tariffAddonsDLSF = tariffAddons?.map(tariffAddon => {
            return {
                'id': tariffAddon?.bpo,
                'price': formatPriceForDL(tariffAddon?.price),
                'title': tariffAddon?.title,
            }

        });
        if (tariffAddonsDLSF && tariffAddonsDLSF?.length > 0) {
            dlsfObject["tariffAddon"] = tariffAddonsDLSF;
        }

    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);

}

function setTariffAddToCartDataLayerCart(vc) {
    const { tariff, device, paymentType, participation } = vc.getAll();

    let isDeviceNoTariff = false;

    //if (tariff?.tariffCode === 'SPO_PREPAIDR' || tariff?.tariffCode === 'STANDALONE') {
    if (tariff?.tariffCode === 'PREPAIDR' || tariff?.tariffCode === 'STANDALONE') {
        isDeviceNoTariff = true;
    }
    let brand = device?.deviceName?.split(' ')[0];
    if (!brand) {
        brand = 'a1';
    }
    const isCash = paymentType === 'gotovina';

    let productItem = {
        'name': `${tariff?.name} ${device?.deviceName}`,      // name of a product that is currently viewed
        'id': `${tariff?.tariffCode}${device?.deviceCode ? ` - ${device?.deviceCode}` : ''}`,            // unique id (SKU) of a product
        'price': +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
        'brand': `a1 - ${brand}`,           // brand/vendor of a product
        'category': `Tarifa - ${deviceTypeNames[device?.deviceType]}`,      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
        // 'variant': device?.deviceMmid,       // product variant. If there are no variants, exclude this key from the dataLayer.push
        'quantity' : 1,              // product quantity
        // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
        // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
    };
    if (isDeviceNoTariff) {
        productItem = {
            'name': `${device?.deviceName}`,      // name of a product that is currently viewed
            'id': `${device?.deviceCode}`,            // unique id (SKU) of a product
            'price': +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': brand,           // brand/vendor of a product
            'category': `${deviceTypeNames[device?.deviceType]}`,      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            // 'variant': device?.deviceMmid,       // product variant. If there are no variants, exclude this key from the dataLayer.push
            'quantity' : 1,              // product quantity
            // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        };
    }

    const isTariffNoDevice = !device?.deviceCode;

    if (isTariffNoDevice) {
        productItem = {
            name: tariff?.name,
            id: tariff?.tariffCode,
            price: +parseFloat(clearPrice(tariff?.tariffMontlyFeeWithoutDevice)).toFixed(2),
            brand: 'a1',
            category: 'Tarifa',
            quantity: 1,
        };
    }

    const dataToSend = {
        'event': 'eec.addToCart',       // name of an event. In this case, always stays as eec.addToCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor RSD
            'add': {                        // name of an action. In this case, always stays as add
                // 'actionField': {
                //     'list': `${deviceType}`         // optional. name of a list from which the product was added to a cart
                // },
                'products': [                   // list of products a user has added to cart
                    productItem,
                ]
            }
        }
    };

    window.dataLayer.push(dataToSend);
    window.location.reload(); // this is moved here from function setTariffModal in VCHandler. Go there and see comment :)

    // SALES FORCE
    const dlsfDevice = {
        deviceCode: device?.deviceCode,
        deviceInstalments: isCash ? 0 : device?.deviceInstalments,
        deviceManufacturer: device?.deviceManufacturer,
        deviceName: device?.deviceName,
        deviceType: deviceTypeNames[device?.deviceType],
        deviceParticipation: participation,
        price: isCash ? formatPriceForDL(tariff?.priceCash) : formatPriceForDL(tariff?.price),
    }

    //virtualCart?.tariff?.tariffMontlyFeeWithoutDevice
    const dlsfObject = {
        "event": 'dlSf',
        "description": 'tariffAdd',
        "tariff": {
            'tariffCode': virtualCart?.tariff?.tariffCode,
            'monthlyFee': virtualCart?.tariff?.monthlyFee,
            'name': virtualCart?.tariff?.name,
            'tariffName': virtualCart?.tariff?.tariffName,
        },
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);
}


function setDataLayerAddTariffToCart({ code, name, monthly, tariffGroupName }) {
    // add-tariff-to-cart

    const productItem = {
        name: name,
        id: code,
        price: +parseFloat(clearPrice(monthly)).toFixed(2),
        brand: 'a1',
        category: 'Tarifa',
        quantity: 1,
        // dimension20: `${deviceName} ${tariffName}`,
    };
    const dataToSend = {
        'event': 'eec.addToCart',       // name of an event. In this case, always stays as eec.addToCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor RSD
            'add': {                        // name of an action. In this case, always stays as add
                // 'actionField': {
                //     'list': `${deviceType}`         // optional. name of a list from which the product was added to a cart
                // },
                'products': [                   // list of products a user has added to cart
                    productItem,
                ]
            }
        }
    };

    window.dataLayer.push(dataToSend);


    const { tariff } = virtualCart.getAll();

    // SALES FORCE
    const dlsfObject = {
        "event": 'dlSf',
        "description": 'tariffAdd',
        "tariff": {
            'tariffCode': code,
            'monthlyFee': +parseFloat(clearPrice(monthly)).toFixed(2),
            'name': name,
            'tariffName': tariffGroupName,
        },
    };
    //
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);
}

function setDataLayerAddTariffAndAdditionToCart({ code, name, monthly, additionTitle, additionBPO, additionPrice, tariffGroupName }) {
    // add-tariff-to-cart

    const productItem = {
        name: name,
        id: code,
        price: +parseFloat(clearPrice(monthly)).toFixed(2),
        brand: 'a1',
        category: 'Tarifa',
        quantity: 1,
        // dimension20: `${deviceName} ${tariffName}`,
    };

    const productAdditionItem = {
        name: `${name} ${additionTitle}`,
        id: additionBPO,
        price: +parseFloat(clearPrice(additionPrice)).toFixed(2),
        brand: 'a1',
        category: 'Tarifni dodaci',
        quantity: 1,
        // dimension20: `${deviceName} ${tariffName}`,
    };
    const dataToSend = {
        'event': 'eec.addToCart',       // name of an event. In this case, always stays as eec.addToCart
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'RSD',         // the currency which is currently used by a visitor RSD
            'add': {                        // name of an action. In this case, always stays as add
                // 'actionField': {
                //     'list': `${deviceType}`         // optional. name of a list from which the product was added to a cart
                // },
                'products': [                   // list of products a user has added to cart
                    productItem,
                    productAdditionItem,
                ]
            }
        }
    };

    window.dataLayer.push(dataToSend);

    // SALES FORCE
    const dlsfObject = {
        "event": 'dlSf',
        "description": 'tariffAdd',
        "tariff": {
            'tariffCode': code,
            'monthlyFee': +parseFloat(clearPrice(monthly)).toFixed(2),
            'name': name,
            'tariffName': tariffGroupName,
        },
        "tariffAddon": {
            'id': additionBPO,
            'price': formatPriceForDL(additionPrice),
            'title': additionTitle,
        },
    };
    //code, name, monthly, additionTitle, additionBPO, additionPrice, tariffGroupName
    // toto
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dlsfObject);
}

function handleDrawerAndTariffProp(e){
    var drawerTitle = $('#tariff-property-drawer-title');
    var drawerContent = $('#tariff-property-drawer-content');

    var dataTitle = e.getAttribute('data-drawer-title');
    var dataContent = e.getAttribute('data-drawer-content');

    $(drawerTitle).html(dataTitle);
    $(drawerContent).html(dataContent);
}

function tariffTooltipHack(externalId){
    var tooltip;
    if($('#tariffTab1').hasClass('active')){
        tooltip = $(".all-tariffs-page-tooltip-wrapper[data-tariff=m1-"+externalId+"]");
    } else if($('#tariffTab2').hasClass('active')){
        tooltip = $(".all-tariffs-page-tooltip-wrapper[data-tariff=m2-"+externalId+"]");
    } else{ //This case is used for Intarifa where we have three tabs
        tooltip = $(".all-tariffs-page-tooltip-wrapper[data-tariff=m3-"+externalId+"]");
    }
    if($(tooltip).hasClass('display_none')){
        setTimeout(function(){
            $(tooltip).removeClass('display_none');
        }, 100);
    } else{
        setTimeout(function(){
            $(tooltip).addClass('display_none');
        }, 180);
    }
}

function setDataLayerProductClick(listName, {code, name, price, brand, category, position, variant, tariffCode, tariffName, oldPrice, discount}, listName2){

    const productItem = {
        name: name,
        id: code,
        price: formatPriceForDL(price),
        brand: brand,
        category: category,
        position: position
    };

    const catName = {
        1: 'Telefoni',
        2: 'Dodatna oprema',
        8: 'Gedžeti',
        7: 'TV/Laptop',
        6: 'Tableti/Konzole',
        3: 'Modemi i ruteri',
    }

    const oldPriceClean = cleanPrice(oldPrice);
    // let discount = 0.00;
    //
    // if (oldPriceClean) {
    //     const priceClean = cleanPrice(price);
    //     discount = oldPriceClean - priceClean;
    // }

    let  productItemGA4 = null;
    if (category === '8' || category === '2' || category === '6' || category === '3' || category === '1') {
        productItemGA4 = {
            item_name: `${name}`,
            item_id: `${code}`,
            price: formatPriceForDL(price),
            currency: 'RSD',
            discount: cleanPrice(discount),
            item_brand: `${brand}`,
            item_category: `${catName[parseInt(category)]}`,
            item_variant: variant,
            item_list_name: listName2 || `PLP | ${catName[parseInt(category)]}`,
            item_list_id: listName2 || `PLP | ${category}`,
            index: parseInt(position),
            quantity: 1,
        };
    } else {
        productItemGA4 = {
            item_name: `${tariffName} - ${name}`,
            item_id: `${tariffCode} - ${code}`,
            price: formatPriceForDL(price),
            currency: 'RSD',
            discount: cleanPrice(discount),
            item_brand: `a1 - ${brand}`,
            item_category: `a1 - ${catName[parseInt(category)]}`,
            item_variant: variant,
            item_list_name: listName2 || `PLP_${catName[parseInt(category)]}`,
            item_list_id: listName2 || `PLP_${category}`,
            index: parseInt(position),
            quantity: 1,
        };
    }


    const dataToSend = {
        'event': 'eec.productClick',    // name of an event. In this case, always stays as eec.productClick
        'ecommerce': {                  // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'click': {                    // name of an action. In this case, always stays as click
                'actionField': {
                    'list': listName        //name of a list in which the product was clicked
                },
                'products': [{
                    productItem
                }]
            }
        }
    }

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(dataToSend);


    const selectItemLayers = window.dataLayer.filter(layer => layer.event === 'select_item');
    if (selectItemLayers?.length === 0) {
        window.dataLayer.push({
            event: 'select_item',
            ecommerce: {
                items: [productItemGA4]
            }
        });
    }
}

//RTB
function handleProductAddToCartRTB(id){
    try{ (function() {
        var prefix = "", hash = "DnYOEz2f7Eec8DRGqqmM", rtbhTags = [];
        rtbhTags.push("pr_"+hash+"_basketadd_" + id);
        var key = "__rtbhouse.lid", lid = window.localStorage.getItem(key);
        if (!lid) {
            lid = ""; var pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 20; i++) lid += pool.charAt(Math.floor(Math.random() * pool.length));
            window.localStorage.setItem(key, lid);}
        rtbhTags.push("pr_"+hash+"_lid_" + lid);
        var ifr = document.createElement("iframe"),
            sr = encodeURIComponent(document.referrer ? document.referrer : ""),
            su = encodeURIComponent(document.location.href ? document.location.href : ""),
            ifrSrc = "https://"+prefix+"creativecdn.com/tags?type=iframe", tmstmp = encodeURIComponent("" + Date.now());
        for(var i=0; i<rtbhTags.length; i++) {ifrSrc += "&id=" + encodeURIComponent(rtbhTags[i]);}
        ifrSrc += "&su=" + su + "&sr=" + sr + "&ts=" + tmstmp;
        ifr.setAttribute("src", ifrSrc); ifr.setAttribute("width", "1");
        ifr.setAttribute("height", "1"); ifr.setAttribute("scrolling", "no");
        ifr.setAttribute("frameBorder", "0"); ifr.setAttribute("style", "display:none");
        ifr.setAttribute("referrerpolicy", "no-referrer-when-downgrade");
        if(document.body){document.body.appendChild(ifr);}
        else{window.addEventListener('DOMContentLoaded', function(){document.body.appendChild(ifr);});}
    })();} catch(e) {}
}

$(document).ready(() => {
    // add-tariff-to-cart
    $('.add-tariff-to-cart').on('click', function(e) {
        var vcDevice = virtualCart.getDevice();

        const code = e.currentTarget.getAttribute('data-tariff-code');
        const price = e.currentTarget.getAttribute('data-tariff-monthly');
        const name = e.currentTarget.getAttribute('data-tariff-name');
        const tariffGroupName = e.currentTarget.getAttribute('data-tariff-group-name');

        if (vcDevice.isEmpty()) {
            setDataLayerAddTariffToCart({
                code,
                name,
                monthly: price,
                tariffGroupName,
            });
        } else {
            $('#device-exists-warning .modal-continue').on('click', function(event) {
                setDataLayerAddTariffToCart({
                    code,
                    name,
                    monthly: price,
                    tariffGroupName,
                });
                event.preventDefault();
            });
        }
    });

    $('.add-addition-to-cart').on('click', function(e) {
        var vcDevice = virtualCart.getDevice();

        const code = e.currentTarget.getAttribute('data-tariff-code');
        const price = e.currentTarget.getAttribute('data-tariff-monthly');
        const name = e.currentTarget.getAttribute('data-tariff-name');
        const tariffGroupName = e.currentTarget.getAttribute('data-tariff-group-name');

        const additionBPO = e.currentTarget.getAttribute('data-addition-bpo');
        const additionPrice = e.currentTarget.getAttribute('data-addition-price');
        const additionTitle = e.currentTarget.getAttribute('data-addition-title');

        if (vcDevice.isEmpty()) {
            setDataLayerAddTariffAndAdditionToCart({
                code,
                name,
                monthly: price,
                additionBPO,
                additionPrice,
                additionTitle,
                tariffGroupName
            });
        } else {
            $('#device-exists-warning .modal-continue').on('click', function(event) {
                setDataLayerAddTariffAndAdditionToCart({
                    code,
                    name,
                    monthly: price,
                    additionBPO,
                    additionPrice,
                    additionTitle,
                    tariffGroupName
                });
                event.preventDefault();
            });
        }

    });

    $('.tariff-cta-add-to-cart').on('click', function(e) {

        var vcDevice = virtualCart.getDevice();
        const noAccGadgetsTariffsString = no_acc_gadgets_tariffs;
        const noAccGadgetsTariffs = noAccGadgetsTariffsString.split(',');
        var tariffCode = e.currentTarget.getAttribute('data-tariff-code');
        if (noAccGadgetsTariffs.includes(tariffCode)) {
            virtualCart.resetGadgets();
            virtualCart.resetAccessories();
        }
        var tariffExternalId = e.currentTarget.getAttribute('data-tariff-external-id');
        var tariffName = e.currentTarget.getAttribute('data-tariff-name');
        var tariffMonthly = e.currentTarget.getAttribute('data-tariff-monthly');
        var tariffPropsString = e.currentTarget.getAttribute('data-tariff-props');

        var tariffPropsStringWithDevice = e.currentTarget.getAttribute('data-tariff-props-with-device');
        var tariffPropsStringWithoutDevice = e.currentTarget.getAttribute('data-tariff-props-without-device');

        var tariffShortDescription = e.currentTarget.getAttribute('data-tariff-short-description');
        var tariffShortDescriptionWithDevice = e.currentTarget.getAttribute('data-tariff-short-description-with-device');
        var tariffShortDescriptionWithoutDevice = e.currentTarget.getAttribute('data-tariff-short-description-without-device');

        var tariffPropsSplited = tariffPropsString.split(';');
        var tariffPropsSplitedWithDevice = tariffPropsStringWithDevice.split(';');
        var tariffPropsSplitedWithoutDevice = tariffPropsStringWithoutDevice.split(';');
        var tariffProps = [];

        var isWithDeviceSelected = !!$('#tariffTab-1').hasClass('active-tab-switcher');

        var tariffOldPriceNoDevice = e.currentTarget.getAttribute('data-tariff-old-price-no-device');
        var tariffOldPriceWithDevice = e.currentTarget.getAttribute('data-tariff-old-price-with-device');

        // if (virtualCart.process === 'add_accessory;accessories') {
        //     virtualCart.process = '';
        // }
        virtualCart.process = '';

        tariffPropsSplited.forEach(function(prop) {
            if (prop) {
                tariffProps.push(prop);
            }
        });

        if(isWithDeviceSelected) {
            tariffPropsSplitedWithDevice.forEach(function(prop) {
                if (prop) {
                    tariffProps.push(prop);
                }
            });
        } else {
            tariffPropsSplitedWithoutDevice.forEach(function(prop) {
                if (prop) {
                    tariffProps.push(prop);
                }
            });
        }

        if (vcDevice.isEmpty()) {
            // virtualCart.setTariff(tariffCode, null, null, tariffExternalId, 'NEO', tariffName, null, tariffMonthly, tariffProps);
            virtualCart.setTariffNew({
                tariffCode: tariffCode,
                externalId: tariffExternalId,
                tariffName: 'NEO',
                name: tariffName,
                monthlyFeeWithoutDevice: tariffMonthly,
                properties: tariffProps,
                tariffMonthlyPriceOld: tariffOldPriceNoDevice,
                tariffMonthlyDevicePriceOld: tariffOldPriceWithDevice,
                shortDescription: tariffShortDescription,
                shortDescriptionWithDevice: tariffShortDescriptionWithDevice,
                shortDescriptionWithoutDevice: tariffShortDescriptionWithoutDevice
            })

            var boosterId = e.currentTarget.getAttribute('data-booster-id');
            var boosterBPO = e.currentTarget.getAttribute('data-booster-bpo');
            var boosterPromoPrice = e.currentTarget.getAttribute('data-booster-promo-price');
            var boosterPrice = e.currentTarget.getAttribute('data-booster-price');
            var boosterValidForSPO =  e.currentTarget.getAttribute('data-booster-valid-for-SPO');
            var boosterBpoPromo =  e.currentTarget.getAttribute('data-booster-bpo-promo');
            var boosterTitle = e.currentTarget.getAttribute('data-booster-title');

            if(boosterId) {
                var boosterId1 = boosterId.split(',')[0]
                var boosterId2 = boosterId.split(',')[1]

            }

            if(boosterBPO) {
                var boosterBPO1 = boosterBPO.split(',')[0]
                var boosterBPO2 = boosterBPO.split(',')[1]

            }

            if(boosterPromoPrice) {
                var boosterPromoPrice1 = boosterPromoPrice.split(',')[0]
                var boosterPromoPrice2 = boosterPromoPrice.split(',')[1]

            }

            if(boosterPrice) {
                var boosterPrice1 = boosterPrice.split(',')[0]
                var boosterPrice2 = boosterPrice.split(',')[1]

            }

            if(boosterValidForSPO) {
                var boosterValidForSPO1 = boosterValidForSPO.split(',')[0]
                var boosterValidForSPO2 = boosterValidForSPO.split(',')[1]

            }


            if(boosterBpoPromo) {
                var boosterBpoPromo1 = boosterBpoPromo.split(',')[0]
                var boosterBpoPromo2 = boosterBpoPromo.split(',')[1]

            }


            if(boosterTitle) {
                var boosterTitle1 = boosterTitle.split(',')[0]
                var boosterTitle2 = boosterTitle.split(',')[1]

            }

            virtualCart.removeAllAddons();


            if(boosterId1 && boosterBPO1) {

                virtualCart.addTariffAddon({ // eslint-disable-line no-undef
                    id: boosterId1,
                    bpo: boosterBPO1,
                    price: boosterPrice1,
                    is_booster: true,
                    title: boosterTitle,
                    props: boosterId1,
                    bpo_valid_for_SPO: boosterValidForSPO1,
                    promo_price: boosterPromoPrice1,
                    bpo_added_with_tariff: tariffCode,
                    bpo_promo: boosterBpoPromo1
                });

            }


            if(boosterId2 && boosterBPO2) {

                virtualCart.addTariffAddon({ // eslint-disable-line no-undef
                    id: boosterId2,
                    bpo: boosterBPO2,
                    price: boosterPrice2,
                    is_booster: true,
                    title: boosterTitle2,
                    props: boosterId2,
                    bpo_valid_for_SPO: boosterValidForSPO2,
                    promo_price: boosterPromoPrice2,
                    bpo_added_with_tariff: tariffCode,
                    bpo_promo: boosterBpoPromo2
                });

            }

            let moreBoosters = false;
            let boosterArr = [];
            if(boosterId1 && boosterId2) {
                boosterArr.push(getBoosterNameById(boosterId1));
                boosterArr.push(getBoosterNameById(boosterId2));
                moreBoosters = true;
            }
            let boosterName = "";
            if(moreBoosters) {
                boosterName = boosterArr[0] + ", " + boosterArr[1];
            } else {
                boosterName = boosterTitle ? boosterTitle : "";
            }

            virtualCart.setAll();
            // console.log('virtualCart.setAll() 1111')

            // setDataLayerNoDevice({tariff: virtualCart.tariff, tariffAddons: virtualCart.tariffAddons});
            document.location.href = '/privatni/cart';
        } else {
            $('#device-exists-warning').addClass('is-on');
            $('#device-exists-warning .modal-cancel').on('click', function(event) {
                $('#device-exists-warning').removeClass('is-on');
                event.preventDefault();
            })
            $('#device-exists-warning .modal-continue').on('click', function(event) {
                virtualCart.clearCart();
                // virtualCart.setTariff(tariffCode, null, null, tariffExternalId, 'NEO', tariffName, null, tariffMonthly, tariffProps);
                virtualCart.setTariffNew({
                    tariffCode: tariffCode,
                    externalId: tariffExternalId,
                    tariffName: 'NEO',
                    name: tariffName,
                    monthlyFeeWithoutDevice: tariffMonthly,
                    properties: tariffProps,
                    tariffMonthlyPriceOld: tariffOldPriceNoDevice,
                    tariffMonthlyDevicePriceOld: tariffOldPriceWithDevice,
                    shortDescription: tariffShortDescription,
                    shortDescriptionWithDevice: tariffShortDescriptionWithDevice,
                    shortDescriptionWithoutDevice: tariffShortDescriptionWithoutDevice
                })

                var boosterId = e.currentTarget.getAttribute('data-booster-id');
                var boosterBPO = e.currentTarget.getAttribute('data-booster-bpo');
                var boosterPromoPrice = e.currentTarget.getAttribute('data-booster-promo-price');
                var boosterPrice = e.currentTarget.getAttribute('data-booster-price');
                var boosterValidForSPO =  e.currentTarget.getAttribute('data-booster-valid-for-SPO');
                var boosterBpoPromo =  e.currentTarget.getAttribute('data-booster-bpo-promo');
                var boosterTitle =  e.currentTarget.getAttribute('data-booster-title');

                virtualCart.removeAllAddons();
                if(boosterId && boosterBPO) {
                    virtualCart.addTariffAddon({ // eslint-disable-line no-undef
                        id: boosterId,
                        bpo: boosterBPO,
                        price: boosterPrice,
                        is_booster: true,
                        title: boosterTitle,
                        props: boosterId,
                        bpo_valid_for_SPO: boosterValidForSPO,
                        promo_price: boosterPromoPrice,
                        bpo_added_with_tariff: tariffCode,
                        bpo_promo: boosterBpoPromo
                    });

                    const boosterName = boosterTitle ? boosterTitle : "";

                    const attributeData = getTariffCardAttributeWithoutDeviceData(this);
                    const internetData = getTariffCardInternetWithoutDeviceData(this);
                    // console.log($(this).closest('.m-tariff-card'), 'THIS 1')
                    const dlToSend = {
                        action: 'Dodaj u korpu',
                        tariffBoosterName: boosterName,
                        tariffName: tariffName,
                        isPromo: !!boosterBpoPromo,
                        internet: internetData?.replace(/\n/g, " "),
                        attribute: attributeData?.replace(/\n/g, " "),
                        basePrice: tariffOldPriceNoDevice ? tariffOldPriceNoDevice : tariffMonthly,
                        finalPrice: tariffMonthly,
                        isWithDevice: $('.switch-with-without-device-input').prop('checked'),
                        tariffCategory: $('.tariff-section-switcher.active p').text(),
                    };

                    boosterDataLayer(dlToSend)
                }

                virtualCart.setAll();
                // console.log('virtualCart.setAll() 222')
                // setDataLayerNoDevice({tariff: virtualCart.tariff, tariffAddons: virtualCart.tariffAddons});
                document.location.href = '/privatni/cart';
                event.preventDefault();
            });
        }
        e.preventDefault();
    });

    const currentUrl = window.location.search;
    if(currentUrl.indexOf('login') > -1){
        const loginDrawer = $('#drwr-mojA1-login');
        if(loginDrawer.length !== 0) {
            $(loginDrawer).addClass('is-on');
        }
    }
});

// data layer productDetail

const setProductDetailEventPDP = ({ name, id, price, brand, category, variant, productListName }, dataForGA4) => {

    const product = {
        'name': `${brand} ${name}`,      // name of a product that is currently viewed.
        'id': id,            // unique id (SKU) of a product
        'price': price,            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
        'brand': brand,           // brand/vendor of a product
        'category': category,      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung-galaxy20)
        // 'dimensionN': '{{custom product attribute}}', //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
        // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
    };
    if (variant) {
        product['variant'] = variant; // product variant. If there are no variants, exclude this key from the dataLayer.push. Example: color of the product.
    }
    const dataToSend = {
        'event': 'eec.productDetail',		// name of an event. In this case, always stays as eec.productDetail
        'ecommerce': {						// ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'detail': {						// name of an action. In this case, always stays as detail
                'actionField': {'list': productListName},    // optional. if this event happened in a certain list, pass its name with this key Examples: Telefoni, Gedžeti, TV/Laptop, Dodatna Oprema, Tableti/Konzole, Modemi i ruteri, Pretraga,
                'products': [                   // list of products a user has purchased
                    product,
                ]
            }
        }
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataToSend);


    setTimeout(() => {
        window.dataLayer.push({
            event: 'view_item',
            ecommerce: {
                items: [dataForGA4]
            }
        });
    }, 1000)

}

const setProductsDetailsEventTariffPage = ({ products, productListName }) => {
    if (!products || !Array.isArray(products) || products?.length < 1) {
        return;
    }
    const dataToSend = {
        'event': 'eec.productDetail',		// name of an event. In this case, always stays as eec.productDetail
        'ecommerce': {						// ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'detail': {						// name of an action. In this case, always stays as detail
                'actionField': {'list': productListName},    // optional. if this event happened in a certain list, pass its name with this key Examples: Telefoni, Gedžeti, TV/Laptop, Dodatna Oprema, Tableti/Konzole, Modemi i ruteri, Pretraga,
                'products': products        // list of products a user has purchased
            }
        }
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataToSend);

    // window.dataLayer.push({
    //     event: 'view_item',
    //     ecommerce: {
    //         items: [{
    //             item_name: name,              //Product Name - Type: string
    //             item_id: id,                       //Product ID/SKU - Type: string
    //             price: price,                              //Product price - Type: number
    //             currency: 'RSD',                             //Currency of the price that is collected - Type: string
    //             discount: 0.00,                              //Any discount associated with this product - Type: number
    //             item_brand: brand,                        //Product Brand - Type: string
    //             item_category: category,                    //Product Category - Type: string
    //             // item_category2: 'item_category2',            //Product Sub-Category 1 - Type: string
    //             // item_category3: 'item_category3',            //Product Sub-Category 2 - Type: string
    //             // item_category4: 'item_category4',            //Product Sub-Category 3 - Type: string
    //             // item_category5: 'item_category5',            //Product Sub-Category 4 - Type: string
    //             item_variant: variant,                //Variant of the product e.g. size, color, variant ID - Type: string
    //             quantity: 1                                  //Product quantity - Type: number
    //         }]
    //     }
    // });
}



const setCheckoutStep1Datalayer = () => {
    const {device, tariff, accessories, gadgets, tariffAddons, paymentType, insurance} = virtualCart.getAll();
    const isCash = paymentType === 'gotovina';
    let isDeviceNoTariff = false;

    //if ((tariff?.tariffCode === 'SPO_PREPAIDR' || tariff?.tariffCode === 'STANDALONE') && device?.deviceCode) {
    if ((tariff?.tariffCode === 'PREPAIDR' || tariff?.tariffCode === 'STANDALONE') && device?.deviceCode) {
        isDeviceNoTariff = true;
    }

    let tariffProductItem = null;
    if (tariff?.tariffCode && !device?.deviceCode) {
        // const { code, name, monthly, tariffGroupName } = tariff;
        let monthly = null;
        if (!device?.deviceCode) {
            monthly = tariff?.tariffMontlyFeeWithoutDevice
        } else {
            monthly = tariff?.monthlyFee;
        }
        tariffProductItem = {
            name: tariff?.name,
            id: tariff?.tariffCode,
            price: +parseFloat(clearPrice(monthly)).toFixed(2),
            brand: 'a1',
            category: 'Tarifa',
            quantity: 1,
        };
    }
    let deviceProductItem = null;
    if(isDeviceNoTariff) {

        let price = +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2);
        let brand = device?.deviceName?.split(' ')[0];
        deviceProductItem = {
            'name': removeFirstWord(device?.deviceName),
            'id': device?.deviceCode,
            'price': price,
            'brand': brand,
            'variant': device?.deviceMmid,
            'category': deviceTypeNames[device?.deviceType],
            'quantity' : 1,
        }
    }
    if(device?.deviceCode && !isDeviceNoTariff) {
        let brand = device?.deviceName?.split(' ')[0];
        if (!brand) {
            brand = 'a1';
        }
        deviceProductItem = {
            'name': `${tariff?.name} - ${removeFirstWord(device?.deviceName)}`,      // name of a product that is currently viewed
            'id': `${tariff?.tariffCode}${device?.deviceCode ? ` - ${device?.deviceCode}` : ''}`,            // unique id (SKU) of a product
            'price': +parseFloat(clearPrice(isCash ? tariff?.priceCash : tariff?.price)).toFixed(2),            // price of a product (The price of one item. The value must not include anything else except number separated by a decimal point. Don't use a comma as the separator, and don't include any currency symbols.)
            'brand': `a1 - ${brand}`,           // brand/vendor of a product
            'category': `Tarifa - ${deviceTypeNames[device?.deviceType]}`,      // Product category of the item. Can have maximum five levels of hierarchy. category/sub-category (ex. device/mobile-phone/samsung/galaxy20)
            'variant': device?.deviceMmid,       // product variant. If there are no variants, exclude this key from the dataLayer.push
            'quantity' : 1,              // product quantity
            // 'dimension20': tariff?.name, //optional. A Product-scoped Custom Dimension for index number N. If there are no variants, exclude this key from the dataLayer.push
            // 'metricN': '{{custom product metric}}' //optional. A Product-scoped Custom Metric for index number N. If there are no variants, exclude this key from the dataLayer.push
        };
    }
    let accessoriesProductList = [];
    if(accessories?.length > 0) {
        accessoriesProductList = accessories.map(acc => {
            return {
                'id': acc?.accessoryCode,
                'name': acc?.accessoryName,
                'price': acc?.accessoryPrice,
                'brand': acc?.accessoryManufacturerName,
                'category': deviceTypeNames['2'],
                'quantity' : 1,
            }
        })

    }

    let gadgetsProductList = [];
    if(gadgets?.length > 0) {
        gadgetsProductList = gadgets.map(acc => {
            return {
                'id': acc?.accessoryCode,
                'name': acc?.accessoryName,
                'price': acc?.accessoryPrice,
                'brand': acc?.accessoryManufacturerName,
                'category': deviceTypeNames['2'],
                'quantity' : 1,
            }
        })

    }
    let tariffAddonsProductItems = [];

    if (tariffAddons?.length) {
        tariffAddonsProductItems = tariffAddons?.map(tariffAddonSingle => {
            return {
                name: tariffAddonSingle?.title,
                id: tariffAddonSingle?.bpo,
                price: +parseFloat(clearPrice(tariffAddonSingle?.price)).toFixed(2),
                brand: 'a1',
                category: 'Tarifni dodaci',
                quantity: 1,
                // dimension20: `${deviceName} ${tariffName}`,
            }
        })
    }

    // if(tariffAddons?.length > 0) {
    //
    // }
    let products = [];
    if (tariffProductItem) {
        products.push(tariffProductItem);
    }
    if (deviceProductItem) {
        products.push(deviceProductItem);
    }
    if (accessoriesProductList?.length > 0) {
        products = [...products, ...accessoriesProductList];
    }
    if (gadgetsProductList?.length > 0) {
        products = [...products, ...gadgetsProductList];
    }
    const dataToSend = {
        'event': 'eec.checkout',                // name of an event. In this case, always stays as eec.checkout
        'ecommerce': {                          // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'checkout': {                       // name of an action. In this case, always stays as checkout
                'actionField': {
                    'step': 1                   // number of the checkout step that a user has entered (must always be 1 in this situation)
                },
                'products': products
            }
        }
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataToSend);


    if (tariffAddonsProductItems?.length > 0) {
        products = [...products, ...tariffAddonsProductItems];
    }

    const ga4Products = products.map(p => {
        let variant = p.variant;

        if (p.id.startsWith('AD_')) {
            variant = p.id.replace('AD_', '');
        }
        // tmp logic (hardcode need to be fixed)
        let brand = p.brand;
        if (!brand && p.name.includes('iPhone')) {
            brand = 'Apple';
        }
        return {
            item_name: p.name,
            item_id: p.id,
            price: cleanPrice(p.price),
            currency: 'RSD',
            discount: 0.00,
            item_brand: brand,
            item_category: p.category,
            item_variant: variant,
            quantity: 1,
        }
    });

    let processNameToSendGA = processNameToSend[virtualCart?.process];

    if (isDeviceNoTariff) {
        processNameToSendGA = 'Standalone';
    }

    //console.log('JASDAS');
    const ga4DataToSend = {
        event: 'begin_checkout',
        process: processNameToSendGA,
        ecommerce: {
            items: ga4Products,
        }
    };
    //console.log(JSON.stringify(ga4DataToSend), 'ga4Products');
    //console.log(ga4Products, 'ga4Products');
    window.dataLayer.push(ga4DataToSend);

}

const setDLViewPromotion = () => {

    let bannerDLs = [];
    $('.o-banner-slider__banner-wrapper .o-banner').each((index, banner) => {
        const bannerId = $(banner).attr('data-banner-id');
        const bannerName = $(banner).attr('data-banner-name');
        const bannerCreative = $(banner).attr('data-banner-creative');
        if (bannerId && bannerName && bannerCreative) {
            bannerDLs = [...bannerDLs, {
                promotion_id: bannerId,                             // Required. Promotion Campaign ID - Type: String
                promotion_name: bannerName,                      // Required. Promotion Campaign Name - Type: String
                creative_name: bannerCreative,                     // Promotion Creative Name - Type: String
                creative_slot: index,                                 // Promotion Creative Slot - Type: String
                location_id: 'hero_banner'                          // Promotion Creative Location ID - Type: String
            }]
        }
    })

    if (bannerDLs.length > 0) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'view_promotion',
            ecommerce: {
                items: bannerDLs,
            }
        });
    }
}


$(document).ready(() => {
    // tweakTariffDrawers()
    setDLViewPromotion();
})


const boosterDataLayer = ({ action, tariffBoosterName, tariffName, isPromo, internet, attribute, basePrice, finalPrice, isWithDevice, tariffCategory }) => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'tariff_engagement',
        'action': action, //"Dodaj u korpu / Izaberi telefon / Više o tarifi / Tariff Details Expand / Tariff Details Collapse",
        'tariff_addon': tariffBoosterName, //"Senior/junior / Net & Čet / APP mix",
        'tariff_name': tariffName, //"Sigurica / Sigurica Plus / Start / Neo surf",
        // 'flag': isPromo ? 'promo' : 'booster', // "booster / promo",
        'internet': internet, // "500 MB / 1 GB / 4 GB / Unlimited",
        'attribute': attribute, // "400 min/sms / 2000 RSD kredita / Unlimited min/sms",
        'base_price': cleanPrice(basePrice), //"1399 / 700 / 2000",
        'final_price': cleanPrice(finalPrice), //"1199 / 700 / 1200",
        'device_toggle': isWithDevice ? 'Sa uredjajem' : 'bez uredjaja', // "Sa uredjajem / bez uredjaja",
        'tariff_category': tariffCategory, // "Sve tarife / NEO Tarife / Osnovne Tarife"
    });
}
//Returns true if it is a DOM element
function isElement(o){
    return (
        typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
            o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName==="string"
    );
}
//Returns true if it is a DOM node
function isNode(o){
    return (
        typeof Node === "object" ? o instanceof Node :
            o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName==="string"
    );
}

/**
 *
 * @param cardChildEl HTMLElement - if it is child in card it should find attribute
 */
const getTariffCardAttributeWithoutDeviceData = (cardChildEl) => {
    if (!cardChildEl && (!isElement(cardChildEl) || !isNode(cardChildEl))) {
        return null
    }
    const cardEl = $(cardChildEl).closest('.m-tariff-card');
    const textElArr = cardEl.find('.short-description-without-device p');//.get(1);
    const textEl = textElArr && textElArr.length > 0 ? textElArr.get(1) : null;
    if (textEl) {
        return textEl.innerText;
    }
    return '';
}
const getTariffCardInternetWithoutDeviceData = (cardChildEl) => {
    if (!cardChildEl && (!isElement(cardChildEl) || !isNode(cardChildEl))) {
        return null
    }
    const cardEl = $(cardChildEl).closest('.m-tariff-card');
    const textEl = cardEl.find('.short-description-without-device p').get(0);
    // return textEl.innerText;
    if (textEl?.innerText) {
        return textEl.innerText;
    }
    return null
}

const getBoosterNameById = (id) => {
    let  boosterName= '';
    if (id === '246') {
        boosterName = 'Junior';
    } else if(id === '247') {
        boosterName = 'Senior';
    } else if(id === '248') {
        boosterName = 'App Mix';
    } else if(id === '249') {
        boosterName = 'Net&Chat';
    }

    return boosterName;
}

const getParam = (param) => {
    return new URLSearchParams(window.location.search).get(param);
}

window.onload = function() {

    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
    } else {
        window.dispatchEvent(new Event('resize'));

    }

    setTimeout(function() {
        var cookieCTA = $('#onetrust-accept-btn-handler')
        if(getParam('zone')) {
            $(cookieCTA).trigger('click')
        }
    }, 300)
};


// New Swiper logic
const createSwiperSlider = (element) => {

    const isDebug = true;

    const id = $(element).attr('id')

    const navigationNextButtonId = "#" + id + "__next";
    const navigationPrevButtonId = "#" + id + "__prev";
    //console.log(navigationNextButtonId, 'navigationNextButtonId');

    let options =  {
        slidesPerView: 2,
        navigation: {
            nextEl: navigationNextButtonId,
            prevEl: navigationPrevButtonId,
        },
        breakpoints: {
            // when window width is >= 320px
            280: {
                slidesPerView: "auto",
                freeMode: {
                    enabled: true,
                    sticky: false,
                },
            },
            1024: {
                freeMode: {
                    enabled: false,
                    sticky: false,
                },
            }
        }
    }

    let isAllTariffs = false;

    if($(element).hasClass('all-tariffs-swiper')) {

        let shouldItSlide = true
        let swiperWrapper = $(element).children()
        let countCards = $(element).children().children('div').length;

        if(countCards <= 4 && !$(element).hasClass('dont-check')) {
            shouldItSlide = false
        }

        // Inside this parent we have two wrappers one for navigation and other for carousel
        let elementParent = $(element).parent()
        let elementNavigationNext = $(elementParent).children()[1]
        let elementNavigationPrev = $(elementParent).children()[0]

        options = {
            slidesPerView: 4,
            // spaceBetween: 30,
            updateOnWindowResize: true,
            grabCursor: true,
            loop: false,
            // pagination: {
            //     el: ".swiper-pagination",
            //     type: "progressbar",
            // },
            navigation: {
                nextEl: elementNavigationNext,
                prevEl: elementNavigationPrev,
            },
            breakpoints: {
                // when window width is >= 320px
                280: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                600: {
                    slidesPerView: "auto",
                },
                // 760: {
                //     slidesPerView: "auto",
                //     loop: true,
                //     centeredSlides: false,
                //     spaceBetween: 0,
                //     // centeredSlides: true,
                // },
                1280: {
                    slidesPerView: 4,
                    // centeredSlides: false,
                },
            },
            // on: {
            //     progress: function(progress) {
            //         console.log('Swiper progress:', progress);
            //     },
            // },
        }

        if(countCards <= 4) {
            options.centeredSlides = true
            options.centeredSlidesBounds = true
        }

        //todo: observer to track resolution, this is usefully for development only, not for users

        var isMobile = window.matchMedia('screen and (max-width: 768px)').matches;

        if(!shouldItSlide) {
            if(!isMobile) {
                options.allowTouchMove = false
                $(swiperWrapper).addClass('noSwipe')
            }
        }

        isAllTariffs = true

    }

    if($(element).hasClass('top-ponuda-hp')) {

        let shouldItSlide = true
        let swiperWrapper = $(element).children()
        let countCards = $(element).children().children('div').length;

        if(countCards <= 4 && !$(element).hasClass('dont-check')) {
            shouldItSlide = false
        }
        options = {
            slidesPerView: 4,
            // spaceBetween: 30,
            updateOnWindowResize: true,
            grabCursor: true,
            loop: false,
            navigation: {
                nextEl: navigationNextButtonId,
                prevEl: navigationPrevButtonId,
            },
            breakpoints: {
                // when window width is >= 320px
                280: {
                    slidesPerView: "auto",
                    freeMode: {
                        enabled: false,
                        sticky: false,
                    },
                },
                1024: {
                    freeMode: {
                        enabled: false,
                        sticky: false,
                    },
                }
            }
        }

        if(countCards <= 4) {
            options.centeredSlides = true
            options.centeredSlidesBounds = true
        }

        //todo: observer to track resolution, this is usefully for development only, not for users

        var isMobile = window.matchMedia('screen and (max-width: 768px)').matches;

        if(!shouldItSlide) {
            if(!isMobile) {
                options.allowTouchMove = false
                $(swiperWrapper).addClass('noSwipe')
            }
        }

    }

    if($(element).hasClass('pdp-modal-swiper')) {

        let nextNav = document.querySelector("."+ id + "-next")
        let prevNav = document.querySelector("."+ id + "-prev")

        options.navigation = {
            nextEl: nextNav,
            prevEl: prevNav,
        }

        options.spaceBetween = 20

        options.breakpoints[360] = {
            slidesPerView: "auto",
            spaceBetween: 20,
        }
    }

    if($(element).hasClass('dont-check')) {

        options.breakpoints[1280] = {
            slidesPerView: 3,
        }
        // options.

        // options.centeredSlides = true
        // options.centeredSlidesBounds = true
    }

    // todo: move all data-attributes funcionality to another function
    let slidesPerView = element.getAttribute('data-swiper_slides-per-view')
    let slidesPerView320 = element.getAttribute('data-swiper_slides-per-view-320')
    let slidesPerView480 = element.getAttribute('data-swiper_slides-per-view-480')
    let slidesPerView760 = element.getAttribute('data-swiper_slides-per-view-760')

    let spaceBetween = element.getAttribute('data-swiper_space-between')
    let spaceBetween320 = element.getAttribute('data-swiper_space-between-320')
    let spaceBetween480 = element.getAttribute('data-swiper_space-between-480')
    let spaceBetween760 = element.getAttribute('data-swiper_space-between-760')

    let slidesPerGroup = element.getAttribute('data-swiper_slides-per-group');

    let navigation = element.getAttribute('data-swiper_navigation')
    let loop = element.getAttribute('data-swiper_loop')

    // modify options object based on data attributes
    if(slidesPerView) {
        options.slidesPerView = parseFloat(slidesPerView)
    }
    if(slidesPerView320) {
        options.breakpoints['320'].slidesPerView = parseInt(slidesPerView320)
    }
    if(slidesPerView480) {
        options.breakpoints['480'].slidesPerView = parseInt(slidesPerView480)
    }
    if(slidesPerView760) {
        options.breakpoints['760'].slidesPerView = parseInt(slidesPerView760)
    }
    if(spaceBetween) {
        options.spaceBetween = parseInt(spaceBetween)
    }
    if(spaceBetween320) {
        options.breakpoints['320'].spaceBetween = parseInt(spaceBetween320)
    }
    if(spaceBetween480) {
        options.breakpoints['480'].spaceBetween = parseInt(spaceBetween480)
    }
    if(spaceBetween760) {
        options.breakpoints['760'].spaceBetween = parseInt(spaceBetween760)
    }

    if(slidesPerGroup) {
        options.slidesPerGroup = parseFloat(slidesPerGroup);
    }

    if(navigation === 'false') {
        options.navigation = {}
    }
    if(loop === 'true') {
        options.loop = true
    }

    if($(element).hasClass('mySwiperKartice')) {
        const testDpiHp = window.matchMedia("(min-resolution: 144dpi) and (max-resolution: 192dpi)").matches;
        let adjustedSlidesPerViewHp = parseFloat(slidesPerView);

        if (testDpiHp) {
            // 3.1 / x = 2.5
            // x = 1.24
            adjustedSlidesPerViewHp = 3;
        }

        options.slidesPerView = adjustedSlidesPerViewHp;

        options.centeredSlides = true
        options.centeredSlidesBounds = true
    }

    if($(element).hasClass('additionSwiper') || $(element).hasClass('faqSwiper')) {
        if(slidesPerView) {

            const mediaQuery = window.matchMedia('(min-resolution: 144dpi) and (max-width: 1024px)');
            const maxPixelRatio = window.matchMedia("(-webkit-max-device-pixel-ratio: 1.5)").matches;
            const isHighDpi = mediaQuery.matches;

            const noScaleRequired = $(element).hasClass('noScale');

            const testDpi = window.matchMedia("(min-resolution: 144dpi) and (max-resolution: 192dpi)").matches;

            let adjustedSlidesPerView = parseFloat(slidesPerView);

            if(isDebug) {}

            if(!noScaleRequired) {
                if (testDpi) {
                    // 3.1 / x = 2.5
                    // x = 1.24
                    adjustedSlidesPerView = 2.5;
                }
            }

            options.slidesPerView = adjustedSlidesPerView;
        }
    }

    // Init Swiper
    let swiper = new Swiper(element, options);
    let previousSlideIndex = swiper.activeIndex;
    let action = null; // Can be "swipeLeft", "swipeRight", "clickLeft", "clickRight"
    let userInteracted = false;
    let isButtonInteraction = false;

    // Listen for navigation button clicks
    const nxtBtn = document.querySelector(navigationNextButtonId);
    if (nxtBtn) {
        nxtBtn.addEventListener('click', function() {
            userInteracted = true;
            action = 'clickRight';
            isButtonInteraction = true;
        });
    }


    const prvBtn = document.querySelector(navigationPrevButtonId);
    if (prvBtn) {
        prvBtn.addEventListener('click', function() {
            userInteracted = true;
            action = 'clickLeft';
            isButtonInteraction = true;
        });
    }
    swiper.on('touchStart', function() {
        userInteracted = true;
    });


// Detect previous button interactions
//     swiper.on('slidePrevTransitionStart', function() {
//         if (!userInteracted) {
//             interactionType = 'prevButton';
//         }
//     });



    swiper.on('slideChange', function (j) {
        setTimeout(() => {
            if (!userInteracted)
                return;
            // console.log('slide changed wrapper', j.$wrapperEl);
            const wrapper = j.$wrapperEl[0].closest('[data-carousel-name]');
            if (wrapper) {
                // console.log(j.activeIndex, 'wrapper');
                const carouselName = wrapper.getAttribute('data-carousel-name');
                if (swiper.activeIndex > previousSlideIndex) {
                    if (isButtonInteraction) {
                        action = 'click left';
                    } else {
                        action = 'scroll left';
                    }
                } else {
                    if (isButtonInteraction) {
                        action = 'click right';
                    } else {
                        action = 'scroll right';
                    }
                }
                if (carouselName) {
                    const carouselGAItems = {
                        'event': 'carouselInteraction',
                        carouselName: carouselName,
                        imagePosition: j.activeIndex + 1,
                        imageName: carouselName,
                        carouselAction: action
                    }
                    //console.log(carouselGAItems, 'carouselGAItems');
                    dataLayer.push(carouselGAItems);
                }
            }

            // Reset and update for next slide change
            action = null;
            previousSlideIndex = swiper.activeIndex;
            userInteracted = false;
            isButtonInteraction = false;
        }, 100)

    });

    // Save Swiper instance
    if(window.swiperInstances) {
        window.swiperInstances[id] = swiper
    } else {
        window.swiperInstances = []
        window.swiperInstances[id] = swiper
    }
}

const initSwiperSlider = () => {
    let sliders = document.querySelectorAll('.swiper-carousel-a1');
    if(sliders.length > 0) {
        sliders.forEach(slider => {
            // temp for neo24 xmass
            if(!slider.classList.contains('temp-disable')){
                createSwiperSlider(slider);
            }
        })
    }
}

const getAndReturnPageName = () => {
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    return parts[parts.length - 1];
}

const tweakTariffDrawers = () => {
    // let drawers = $('.drawer-details-tariff');
    // if(drawers.length > 0) {
    //     for(let i = 0; i < drawers.length; i++) {
    //         let drawer = drawers[i]
    //         $(drawer).detach()
    //         let firstContainer = $(".container")[0]
    //         $(drawer).appendTo(firstContainer)
    //     }
    // }
}

function setDLPDP() {
    var isCash = $('[name="placanje"]:checked').val() === 'gotovina';
    const { tariff, device } = tmpVc.getAll();
    let dataForDL = null;
    const isStandalone = tariff.tariffCode === 'STANDALONE';
    if (isStandalone) {
        dataForDL = {
            name: `{{$item->name}}`,
            id: `${device?.deviceCode}`,
            price: +parseFloat(clearPrice(isCash ? `${tariff?.priceCash}` : `${tariff?.price}`)).toFixed(2),
            brand: '{{$item->model}}',
            category: '{{$deviceType}}',
            variant: `${device?.deviceMmid}`,
            productListName: '{{$deviceType}}',
        };
    } else {
        dataForDL = {
            name: `${tariff?.name} - {{$item->name}}`,
            id: `${tariff?.tariffCode} - ${device?.deviceCode}`,
            price: +parseFloat(clearPrice(isCash ? `${tariff?.priceCash}` : `${tariff?.price}`)).toFixed(2),
            brand: 'a1 - {{$item->model}}',
            category: 'Tarifa - {{$deviceType}}',
            variant: `${device?.deviceMmid}`,
            productListName: '{{$deviceType}}',
        };
    }
    setProductDetailEventPDP(dataForDL);

}


function createModal({
                         id,
                         customClass,
                         title = '',
                         content = '',
                         isCloseDisabled = false,
                         confirmButtonText,
                         hideIcon = false,
                         abortButtonText,
                     }) {
    let closeIconHTML = `<div class="close-icon">
                <img src="https://cdn.a1.rs/a1website/img/a1/icons/icon-x-close.svg" alt="Close modal" width="18" height="18"/>
            </div>`;
    if(isCloseDisabled) {
        closeIconHTML = '';
    }
    let confirmButtonHTML = '';
    if (confirmButtonText) {

        let onClickPart = '';
        confirmButtonHTML = `
                    <button class="a-02-button__flat--primary has-label modal-continue-handler-class" ${onClickPart}  type="button">
                        <span class="a-02-button__label ">${confirmButtonText}</span>
                        <span class="a-02-button__icon " >
                             <img inline-svg src="https://cdn.a1.rs/a1website/img/a1/icons/icon-chevron-right-white.svg" />
                         </span>
                    </button>
            `
    }

    let abortButtonHTML = '';
    if (abortButtonText) {

        abortButtonHTML = `
                    <button class="a-02-button__inverted--primary has-label modal-abort-handler-class" type="button">
                        <span class="a-02-button__label ">${abortButtonText}</span>
                        <span class="a-02-button__icon " >
                             <img inline-svg src="https://cdn.a1.rs/a1website/img/a1/icons/icon-chevron-right-white.svg" />
                         </span>
                    </button>
            `
    }
    return `<div id="${id}" class="m-modal js-modal ${customClass}">
        <div class="overlay " data-modal-id='${id}${isCloseDisabled ? '-not' : ''}'></div>
        <div class="m-modal__content-area">
            ${closeIconHTML}
            <div class="m-modal__type-icon ${hideIcon ? 'display_none' : ''}">
                <img src="https://cdn.a1.rs/a1website/img/a1/modals/icon-warning-red.svg" alt="Close modal" width="29" height="29"/>
            </div>
            <div class="m-modal__title">
                <div  class="a-01-text__secondary-bold--3xl  "  >${title}</div>
            </div>
            <div class="m-modal__content">
                <div class="p-shopping-cart-warning">
                    <div class="p-shopping-cart-warning__desc">
                        <p  class="a-01-text__primary--base  "  >${content}</p>
                    </div>
                </div>
            </div>
            <div class="m-modal__action-buttons">
                ${abortButtonHTML}
                ${confirmButtonHTML}
            </div>
        </div>
    </div>`;
}

function confirmDialog({ id, customClass, title, content, confirmButtonText, abortButtonText, confirmButtonCallback, abortButtonCallback }) {
    const dialogID = `confirm-${id}`;
    const modalHTMl = createModal({
        id: dialogID,
        customClass: `${customClass} is-on`,
        title,
        content,
        isCloseDisabled: true,
        confirmButtonText,
        abortButtonText,
    });
    $(document.body).append(modalHTMl);


    $(`#${dialogID}`).find('.modal-continue-handler-class').on('click', () => {
        if (typeof confirmButtonCallback === 'function') {
            confirmButtonCallback();
        }
    })
    $(`#${dialogID}`).find('.modal-abort-handler-class').on('click', () => {
        if (typeof confirmButtonCallback === 'function') {
            abortButtonCallback();
        }
    })
}


function createLoaderSpinnerWithText({ text }) {
    return `<div class="cp_spinner_wrapper">
            ${text ? text : ''}<span class="cp_spinner cp_round"></span>
        </div>`
}

function createNotAvailableAccessoryItemHTML(accessories, isGadget = false) {
    if(!accessories || !Array.isArray(accessories) || accessories.length === 0) {
        return '';
    }
    return accessories.reduce((accumulator, currentValue) => {
        let acc;
        if (isGadget) {
            acc = virtualCart.getGadget(currentValue);
        } else {
            acc = virtualCart.getAccessory(currentValue);
        }
        return accumulator + `<span class="notAvailableAccessory">${acc?.accessoryComercialName}</span>`;
    }, '');
}



function carouselInteractionDL() {
    window.dataLayer = window.dataLayer || [];

    dataLayer.push({
        'event': 'carouselInteraction',
        carouselName: '{{carousel name}}',
        imagePosition: '{{image position}}',
        imageName: '{{image name}}',
        carouselAction: '{{click, scroll left, scroll right, arrow click left, etc}}'
    });
}

function setHeightOfBoosters() {
    let maxHeight = 0;
    [...$('.booster-mini-prop-wrapper')].forEach(item => {
        const currentElementHeight = item.offsetHeight;
        if (currentElementHeight > maxHeight) {
            maxHeight = currentElementHeight;
        }
        if (maxHeight > 0) {
            $(item).css('height', `${maxHeight}px`)
        }
    })
}

function splitByCommaAndGenerateUrlParams(string, key) {
    if (!string) return '';

    const splited = string.split(',');

    if (splited.length === 0) return `${key}[]=${string}`;

    return splited.reduce((accumulator, currentValue) => `${accumulator}&${key}[]=${currentValue}`, '');
}

window.setInsuranceAddToCartDataLayerPDP = setInsuranceAddToCartDataLayerPDP;
window.setDataLayerRemoveFromCart2 = setDataLayerRemoveFromCart2;
window.removeInsuranceFromCartDataLayer = removeInsuranceFromCartDataLayer;
window.setInsuranceAddToCartDataLayer = setInsuranceAddToCartDataLayer;
window.setDataLayerRemoveFromCart = setDataLayerRemoveFromCart;
window.setDataLayerAddToCart = setDataLayerAddToCart;
window.setAdditionAddToCartDataLayer = setAdditionAddToCartDataLayer;
window.setAdditionRemoveFromCartDataLayer = setAdditionRemoveFromCartDataLayer;
window.setDataLayerAddToCartSingle = setDataLayerAddToCartSingle;
window.setTariffAddToCartDataLayerCart = setTariffAddToCartDataLayerCart;
window.setDataLayerAddToCartAccGadzet = setDataLayerAddToCartAccGadzet;
window.setProductDetailEventPDP = setProductDetailEventPDP;
window.setProductsDetailsEventTariffPage = setProductsDetailsEventTariffPage;
window.handleProductAddToCartRTB = handleProductAddToCartRTB;
window.tariffTooltipHack = tariffTooltipHack;
window.handleDrawerAndTariffProp = handleDrawerAndTariffProp;
window.initStartPageCarousel = initStartPageCarousel;
window.setDataLayerViewCart = setDataLayerViewCart;
window.setDataLayerProductClick = setDataLayerProductClick;
window.setCheckoutStep1Datalayer = setCheckoutStep1Datalayer;
window.setDLViewPromotion = setDLViewPromotion;
window.initBoosters = initBoosters;
window.initBoostersCart = initBoostersCart;
/*window.handleBfNeo20 = function() {}
window.handleBfNeo20Dropdown = function() {};*/
window.initSwiperSlider = initSwiperSlider;
window.getAndReturnPageName = getAndReturnPageName;
window.capitalizeFirstLetter = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
window.setDLPDP = setDLPDP;
window.getTariffCardAttributeWithoutDeviceData = getTariffCardAttributeWithoutDeviceData;
window.getTariffCardInternetWithoutDeviceData = getTariffCardInternetWithoutDeviceData;
window.boosterDataLayer = boosterDataLayer;
window.removeFirstWord = removeFirstWord;
// window.tweakTariffDrawers = tweakTariffDrawers;

window.createLoaderSpinnerWithText = createLoaderSpinnerWithText;
window.confirmDialog = confirmDialog;
window.createModal = createModal;
window.createNotAvailableAccessoryItemHTML = createNotAvailableAccessoryItemHTML;
window.loaderController = LoaderController.getInstance();
window.setHeightOfBoosters = setHeightOfBoosters;
window.splitByCommaAndGenerateUrlParams = splitByCommaAndGenerateUrlParams;
